// two-field-search
import React from "react";
import { Link } from "react-router-dom";
import { RiHome2Line, RiPrinterLine } from "react-icons/ri";
import moment from "moment";

const ServiceInvoice = ({ data }) => {
  const date = new Date();
  return (
    <div className="col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-10">
            <div className="card border-0 rounded shadow px-4 py-5">
              <div className="row-mb-0 text-start">
                <div className="col">
                  <img src={"assets/images/logo-icon.png"} height="75" alt="" />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8 col-md-6 text-start">
                  {/*<img*/}
                  {/*  src={"/assets/images/logo-icon.png"}*/}
                  {/*  height="75"*/}
                  {/*  alt=""*/}
                  {/*/>*/}
                  <h6 className="mt-0 pt-0">Appointment Booking :</h6>
                  <h6 className="mt-1 pt-2">
                    {data?.organisationName.toUpperCase()}
                  </h6>
                  {/*<h6 className="mt-1 pt-2">Address :</h6>*/}
                  <small className="text-muted mb-0">
                    {data?.address1.toUpperCase()}, <br />
                    {data.address2 ? data.address2.toUpperCase() : null}
                    {data?.city.toUpperCase()},{" "}
                    {`${data?.county.toUpperCase()} ${data?.postCode.toUpperCase()}`}
                  </small>
                </div>

                <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <ul className="list-unstyled">
                    <li className="d-flex ms-0">
                      <small className="mb-0 text-muted">Invoice no. : </small>
                      <small className="mb-0 text-dark">
                        &nbsp;&nbsp;{data.orderId}
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Email : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;
                        <Link
                          to="mailto:contact@example.com"
                          className="text-dark"
                        >
                          {data.emailId}
                        </Link>
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Phone : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;
                        <Link to="tel:+152534-468-854" className="text-dark">
                          {data.phoneNumber}
                        </Link>
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Website : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;
                        <Link to="" className="text-dark">
                          www.ziplanerx.com
                        </Link>
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Patient Name : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;{`${data.firstName} ${data.lastName}`}
                      </small>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="pt-4 border-top">
                <div className="row">
                  <div className="col-lg-8 col-md-6">
                    <h5 className="text-muted fw-bold">
                      Invoice{" "}
                      <span className="badge rounded-pill bg-soft-success fw-normal ms-2">
                        {data?.fundingType === "nhs" ? "" : "Paid"}
                      </span>
                    </h5>
                    <h6>{data.serviceName}</h6>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <ul className="list-unstyled">
                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">Issue Dt. : </small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{moment(date).format("MMM Do YYYY")}
                        </small>
                      </li>

                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">
                          Appointment Dt. :{" "}
                        </small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{moment(data.start).format("MMM Do YYYY")}
                        </small>
                      </li>

                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">
                          Start Time. :{" "}
                        </small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{moment(data.start).format("hh:mm a")}
                        </small>
                      </li>

                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">End Time. : </small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{moment(data.end).format("hh:mm a")}
                        </small>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-table pb-4">
                  <div className="table-responsive shadow rounded mt-4">
                    <table className="table table-center invoice-tb mb-0">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="text-start border-bottom p-3"
                            style={{ minWidth: "60px" }}
                          >
                            No.
                          </th>
                          <th
                            scope="col"
                            className="text-start border-bottom p-3"
                            style={{ minWidth: "200px" }}
                          >
                            Item
                          </th>
                          <th
                            scope="col"
                            className="text-center border-bottom p-3"
                            style={{ minWidth: "60px" }}
                          >
                            Qty
                          </th>
                          <th
                            scope="col"
                            className="border-bottom p-3"
                            style={{ minWidth: "130px" }}
                          >
                            Rate
                          </th>
                          <th
                            scope="col"
                            className="border-bottom p-3"
                            style={{ minWidth: "130px" }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="text-start p-3">
                            1
                          </th>
                          <td className="text-start p-3">{data.serviceName}</td>
                          <td className="text-center p-3">1</td>
                          <td className="p-3">
                            {data.fundingType !== "nhs" ? data.serviceCost : 0}
                          </td>
                          <td className="p-3">
                            {data.fundingType !== "nhs" ? data.serviceCost : 0}
                          </td>
                        </tr>
                        {/*<tr>*/}
                        {/*  <th scope="row" className="text-start p-3">*/}
                        {/*    2*/}
                        {/*  </th>*/}
                        {/*  <td className="text-start p-3">Medicine</td>*/}
                        {/*  <td className="text-center p-3">1</td>*/}
                        {/*  <td className="p-3">$ 245</td>*/}
                        {/*  <td className="p-3">$ 245</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*  <th scope="row" className="text-start p-3">*/}
                        {/*    3*/}
                        {/*  </th>*/}
                        {/*  <td className="text-start p-3">*/}
                        {/*    Special Visit Fee(Doctor)*/}
                        {/*  </td>*/}
                        {/*  <td className="text-center p-3">1</td>*/}
                        {/*  <td className="p-3">$ 150</td>*/}
                        {/*  <td className="p-3">$ 150</td>*/}
                        {/*</tr>*/}
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-5 ms-auto">
                      <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                        <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                          Subtotal :
                          <span>
                            {data.fundingType !== "nhs" ? data.serviceCost : 0}
                          </span>
                        </li>
                        <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                          Vat % :<span> 0</span>
                        </li>
                        <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                          Discount % :<span> 0</span>
                        </li>
                        <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                          Taxes :<span> 0</span>
                        </li>
                        <li className="d-flex justify-content-between pe-3 ms-0">
                          Total :
                          <span>
                            {data.fundingType !== "nhs" ? data.serviceCost : 0}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="border-top pt-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="text-sm-start text-muted text-center">
                        <small className="mb-0">
                          Customer Service :{" "}
                          <Link
                            to="tel:+152534-468-854"
                            className="text-warning"
                          >
                            contact@ziplanerx.com
                          </Link>
                        </small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="text-sm-end text-muted text-center">
                        <small className="mb-0">
                          <Link to="/aboutus/terms" className="text-primary">
                            Terms & Conditions
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-4 pt-2">
              <Link
                to="/home"
                onClick={() => window.open("/home", "_self")}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiHome2Line /> Home
              </Link>
              <Link
                to="#"
                onClick={() => window.print()}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiPrinterLine /> Print
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceInvoice;
