import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";

import Carousel from "react-bootstrap/Carousel";
import { FiEye, FiShoppingCart } from "react-icons/fi";
import "tiny-slider/dist/tiny-slider.css";
import Header from "../../components/header/header";
import {
  getProductCategories,
  getProductSubCategories,
  mongoGetProducts,
} from "../../utils/referencedata.services";
import Pagination from "../../components/pagination";
import { productCategoriesData } from "../../data/data";
import CounterTwo from "../../components/counter-two";

export default function PharmacyShopCategoriesMain({ addToCart }) {
  // Metadata
  let params = useParams();
  let className = params.class;
  let metadata = productCategoriesData[className];
  let settings = {
    container: ".slider-range-four",
    items: 3,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 4,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };

  // Variables
  let [categories, setCategories] = useState(null);
  let [subCategories, setSubCategories] = useState(null);
  let [selectedCategory, setSelectedCategory] = useState(null);
  let [selectedSubCategory, setSelectedSubcategory] = useState(null);
  let [productCount, setProductCount] = useState({});

  // Pagination Variables
  let [index, setIndex] = useState(0);
  let [productsData, setProductsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(16);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = productsData.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );
  const nPages = Math.ceil(productsData.length / recordsPerPage);

  // Use Effect
  useEffect(() => {
    let criteria = {
      class: metadata.name,
    };
    getProductCategories(criteria).then((response) => {
      if (response.data) {
        setCategories(response.data);
        setSelectedCategory(response.data[0]);
      }
    });
  }, [metadata.name]);

  // Sub Category
  useEffect(() => {
    let criteria = {};
    criteria["categories_level0"] = metadata.name;
    criteria["categories_level1"] = selectedCategory;
    getProductSubCategories(criteria).then((response) => {
      if (response.data) {
        setSubCategories(response.data);
        if (response.data[0]) {
          setSelectedSubcategory(response.data[0]);
        } else {
          setSelectedSubcategory(response.data[1]);
        }
      }
    });
  }, [selectedCategory]);

  // Products
  useEffect(() => {
    let criteria = {};
    criteria["categories_level0"] = metadata.name;
    criteria["categories_level1"] = selectedCategory;
    if (selectedSubCategory) {
      criteria["categories_level2"] = selectedSubCategory;
    }
    mongoGetProducts(criteria).then((respose) => {
      if (respose.data) {
        setProductsData(
          respose.data.sort((a, b) =>
            a.labels_fulldescription.localeCompare(b.labels_fulldescription),
          ),
        );
      }
    });
  }, [selectedCategory, selectedSubCategory]);

  // Handlers
  let handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  let handleCategorySelect = (e) => {
    // Set Category
    setSelectedCategory(e.target.value);

    // Payload
    let criteria = {
      categories_level0: metadata.name,
      categories_level1: e.target.value,
    };

    getProductSubCategories(criteria).then((response) => {
      if (response.data) {
        setSubCategories(response.data);
        if (response.data[0]) {
          setSelectedSubcategory(response.data[0]);
        } else {
          setSelectedSubcategory(response.data[1]);
        }
      }
    });
  };

  let handleAddToCart = (item) => {
    addToCart(item.productId, item, productCount[item.productId]);
  };

  return (
    <Fragment>
      <Header />

      <section className="home-slider position-relative">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          interval={2000}
        >
          <Carousel.Item>
            <div className="carousel-item active">
              <div
                className="bg-half-170 d-table align-items-center w-100"
                style={{
                  backgroundImage: `url(${metadata.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  overflow: "scroll",
                }}
              >
                <section className="bg-overlay bg-overlay-dark">
                  <div className="container">
                    <div className="row mt-5">
                      <div className="col-lg-12">
                        <div className="heading-title">
                          {/*<h1 className="fw-bold mb-4">{metadata.name}</h1>*/}
                          {/*/!*<p className="para-desc mb-0">ZipLaneRx</p>*!/*/}

                          {/*<div className="mt-4 pt-2">*/}
                          {/*  <Link to="#" className="btn btn-primary">*/}
                          {/*    Shop now*/}
                          {/*  </Link>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="mb-0">
                Check out our{" "}
                <span className="fw-bold text-primary">{metadata.name}</span>{" "}
                Products
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label fw-bold">Select Category</label>
                <select
                  className="form-control"
                  value={selectedCategory}
                  onChange={handleCategorySelect}
                >
                  {categories
                    ? categories.map((met) => {
                        if (met) {
                          return (
                            <option key={met} value={met}>
                              {met}
                            </option>
                          );
                        }
                      })
                    : []}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label fw-bold">
                  Select Sub Category
                </label>
                <select
                  className="form-control"
                  value={selectedSubCategory}
                  onChange={(e) => {
                    setSelectedSubcategory(e.target.value);
                  }}
                >
                  <option defaultValue="default">Select a SubCategory</option>
                  {subCategories
                    ? subCategories.map((met) => {
                        if (met) {
                          return (
                            <option key={met} value={met}>
                              {met}
                            </option>
                          );
                        }
                      })
                    : []}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            {currentRecords?.map((item, index) => {
              if (item.price_trade > 0) {
                return (
                  <div
                    className="col-lg-3 col-md-6 col-12 mt-4 pt-2"
                    key={index}
                  >
                    <div className="card shop-list border-0">
                      <ul className="label list-unstyled mb-0">
                        {/*<li>*/}
                        {/*  <Link*/}
                        {/*    to=""*/}
                        {/*    className="badge rounded-pill badge-success"*/}
                        {/*  >*/}
                        {/*    Featured*/}
                        {/*  </Link>*/}
                        {/*</li>*/}
                        <li className="mt-2 mb-2">
                          <Link
                            to={`/shop/products/${item.productId}`}
                            className="btn btn-icon btn-pills btn-soft-primary"
                          >
                            <FiEye className="icons" />
                          </Link>
                        </li>
                        <li className="mt-0">
                          <Link
                            to=""
                            className="btn btn-icon btn-pills btn-soft-primary"
                            onClick={() => handleAddToCart(item)}
                          >
                            <FiShoppingCart className="icons" />
                          </Link>
                        </li>
                      </ul>
                      <div className="shop-image position-relative overflow-hidden rounded shadow">
                        <Link to={`/shop/products/${item.productId}`}>
                          <img
                            src={`data:image/gif;base64,${item.image}`}
                            className="img-fluid"
                            alt=""
                            style={{ width: 90, height: 125 }}
                          />
                        </Link>
                        <ul className="list-unstyled">
                          <li>
                            <CounterTwo
                              productId={item.productId}
                              setProductCount={setProductCount}
                            />
                          </li>
                        </ul>
                        {/*<ul className="list-unstyled shop-icons">*/}
                        {/*  <li className="mt-2 mb-2">*/}
                        {/*    <Link*/}
                        {/*      to={`/shop/products/${item.productId}`}*/}
                        {/*      className="btn btn-icon btn-pills btn-soft-primary"*/}
                        {/*    >*/}
                        {/*      <FiEye className="icons" />*/}
                        {/*    </Link>*/}
                        {/*  </li>*/}
                        {/*  <li className="mt-0">*/}
                        {/*    <Link*/}
                        {/*      to=""*/}
                        {/*      className="btn btn-icon btn-pills btn-soft-primary"*/}
                        {/*      onClick={() => handleAddToCart(item)}*/}
                        {/*    >*/}
                        {/*      <FiShoppingCart className="icons" />*/}
                        {/*    </Link>*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                      </div>
                      <div className="card-body content pt-4 p-2">
                        <Link
                          to={`/shop/products/${item.productId}`}
                          className="text-dark product-name h6"
                        >
                          {item.labels_fulldescription}
                        </Link>
                        <div className="d-flex justify-content-between mt-1">
                          <h6 className="text-muted small font-italic mb-0 mt-1">
                            £ {item.price_trade ? item.price_trade : 0}
                          </h6>
                          {/*<ul className="list-unstyled text-warning mb-0">*/}
                          {/*  <li className="list-inline-item">*/}
                          {/*    <i className="mdi mdi-star"></i>*/}
                          {/*  </li>*/}
                          {/*  <li className="list-inline-item">*/}
                          {/*    <i className="mdi mdi-star"></i>*/}
                          {/*  </li>*/}
                          {/*  <li className="list-inline-item">*/}
                          {/*    <i className="mdi mdi-star"></i>*/}
                          {/*  </li>*/}
                          {/*  <li className="list-inline-item">*/}
                          {/*    <i className="mdi mdi-star"></i>*/}
                          {/*  </li>*/}
                          {/*  <li className="list-inline-item">*/}
                          {/*    <i className="mdi mdi-star"></i>*/}
                          {/*  </li>*/}
                          {/*</ul>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              path={`/shop/${className}`}
            />
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
