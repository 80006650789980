import React from "react";
import Select from "react-select";
import "./services-search.css";

function ServiceSelect({
  services,
  selectService,
  selectedServiceObject,
  isNhs,
}) {
  const getFilteredServices = () => {
    return services.filter((service) =>
      isNhs.state ? service.isNhs === 1 : service.isPrivate === 1,
    );
  };

  return (
    <div className="col-md mt-4 mt-sm-0">
      <div className="input-group bg-white border rounded">
        <span className="input-group-text border-0">
          <i className="ri-health-book-line text-primary h5 fw-normal mb-0"></i>
        </span>
        <Select
          className="form-control border-0"
          options={getFilteredServices()}
          onChange={selectService}
          value={selectedServiceObject}
          getOptionLabel={(option) => option.serviceName.toUpperCase()}
          menuPosition="fixed"
          isSearchable={true}
          autoFocus={true}
          placeholder="Select Service"
        />
      </div>
    </div>
  );
}

export default ServiceSelect;
