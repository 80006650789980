import axios from "axios";

const baseUrl = process.env.REACT_APP_user_base;

export async function createCustomerLogic(authUser) {
  let customer;
  // Get Email
  const customerPayload = {
    customer_email: authUser.email ? authUser.email : "",
  };

  // Search Customer
  const customers = await searchCustomer(customerPayload);
  console.log(customers);

  // If Exists Return
  if (customers.length > 0) {
    console.log(`customer with email ${authUser.email} exists`);
    sessionStorage.setItem("customerId", customers[0].customer_id);
    return customers[0];
  } else {
    console.log(`customer with email ${authUser.email} does not exists`);
    let customerCreatePayload = {
      customer_email: authUser.email,
      first_nm: authUser.given_name ? authUser.given_name : authUser.nickname,
      last_nm: authUser.family_name ? authUser.family_name : authUser.nickname,
      user_nm: authUser.email,
      public_guid: authUser.sub,
      user_img: authUser.picture ? authUser.picture : null,
    };
    customer = await createCustomer(customerCreatePayload);
    sessionStorage.setItem("customerId", customer.data.customer_id);
    console.log(`customer with email ${authUser.email} created.`);
  }

  // If Not Create
  return customer;
}

export async function searchCustomer(criteria) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  const result = await axios.post(
    `${baseUrl}/api/customers/query/`,
    criteria,
    config,
  );
  return result.data;
}

export async function createCustomer(payload) {
  let response;
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };
  try {
    response = await axios.post(`${baseUrl}/api/customers/`, payload, config);
    return response.data;
  } catch (err) {}
}

export async function updateCustomer(id, payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };
  try {
    return axios.put(`${baseUrl}/api/customers/${id}`, payload, config);
  } catch (err) {}
}

export async function getCustomer(id) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };
  try {
    return axios.get(`${baseUrl}/api/customers/${id}`, config);
  } catch (err) {}
}

export async function searchCustomerViaEmail(criteria) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  try {
    const result = await axios.post(
      `${baseUrl}/api/users/searchCustomersViaEmail/`,
      criteria,
      config,
    );
    return result.data;
  } catch (err) {}
}
