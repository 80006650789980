import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { LuClipboardList } from "../../assets/icons/vander";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";

import Modal from "react-bootstrap/Modal";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import {
  createCustomerLogic,
  getCustomer,
  searchCustomer,
  updateCustomer,
} from "../../utils/usermgmt.service";
import {
  getOrderBooking,
  searchCustomerAppointments,
  searchCustomerOrders,
} from "../../utils/sales.service";
import {
  RiHome2Line,
  RiInkBottleFill,
  RiMedicineBottleFill,
  RiMicroscopeFill,
  RiPrinterLine,
} from "react-icons/ri";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./patient-profile.css";
import {
  birthdateValidator,
  emailValidator,
  phoneValidator,
  postalCodeValidator,
} from "../../utils/validators";
import { LuArrowBigRight } from "react-icons/lu";
import AuthService from "../../utils/auth/auth.service";

const PatientProfile = () => {
  let [activeIndex, setActiveIndex] = useState(1);
  let [activeTableIndex, setActiveTableIndex] = useState(1);
  let [show, setShow] = useState(false);
  let [showOrderInvoice, setShowOrderInvoice] = useState(false);
  let [selectedOrder, setSelectedOrder] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth0User, setAuth0User] = useState(null);
  const auth0 = useAuth0();
  const authService = new AuthService();
  const [customer, setCustomerData] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState("United Kingdom");
  const [postCode, setPostCode] = useState(null);
  const [birthdate, setBirthdate] = useState(new Date());
  const [validForm, setValidForm] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [prescriptions, setPrescriptions] = useState([]);
  const autocompleteRef1 = useRef(null);
  const autocompleteRef2 = useRef(null);
  const autocompleteRef3 = useRef(null);
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: true,
    message: "First Name Is Required",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: true,
    message: "Last Name Is Required",
  });
  const [emailValidation, setEmailValidation] = useState({
    valid: true,
    message: "Email Is Required",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    valid: true,
    message: "Phone Is Required",
  });
  const [addressValidation, setAddressValidaton] = useState({
    valid: true,
    message: "Address Line 1 Is Required",
  });
  const [cityValidation, setCityValidation] = useState({
    valid: true,
    message: "City Is Required",
  });
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    valid: true,
    message: "Postal Code Is Required",
  });
  const [birthdateValidation, setBirthdateValidation] = useState({
    valid: true,
    message: "Birthdate Is Required",
  });

  // Form Validator
  useEffect(() => {
    setValidForm(
      firstNameValidation.valid &&
        lastNameValidation.valid &&
        emailValidation.valid &&
        phoneNumberValidation.valid &&
        postalCodeValidation.valid &&
        addressValidation.valid &&
        cityValidation.valid &&
        birthdateValidation.valid,
    );
  }, [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    phoneNumberValidation,
    addressValidation,
    cityValidation,
    postalCodeValidation,
    birthdateValidation,
  ]);

  // Set Initial Data
  // Set Initial Data
  useEffect(() => {
    // Get Customer Data
    async function getCustomerData(email) {
      let criteria = { customer_email: email ? email : " " };
      return searchCustomer(criteria);
    }

    // Function to get customer data and set it
    const fetchAndSetCustomerData = () => {
      getCustomerData(auth0?.user?.email).then((data) => {
        if (data.length > 0) {
          setCustomerData(data[0]);
          console.log("got customer data");
        }
      });
    };

    // Function to create customer and fetch data
    const createCustomerAndFetchData = () => {
      createCustomerLogic(auth0.user).then(fetchAndSetCustomerData);
    };

    // Set Is Authenticated
    setIsAuthenticated(auth0.isAuthenticated);

    // Set Auth0User
    if (auth0.isAuthenticated) {
      setAuth0User(auth0?.user);

      // Check if access token exists
      if (!sessionStorage.getItem("_accessToken")) {
        auth0.getAccessTokenSilently().then((res) => {
          sessionStorage.setItem("_accessToken", res.toString());

          // Check if customerId exists
          if (!sessionStorage.getItem("customerId")) {
            createCustomerAndFetchData();
          } else {
            fetchAndSetCustomerData();
          }
        });
      } else {
        if (!sessionStorage.getItem("customerId")) {
          createCustomerAndFetchData();
        } else {
          fetchAndSetCustomerData();
        }
      }
    }
  }, [auth0]);

  // Appointments and Orders
  useEffect(() => {
    async function getCustomerAppointments(customer_id) {
      let criteria = {
        customer_id: customer_id ? customer_id : "",
      };
      return searchCustomerAppointments(criteria);
    }

    async function getCustomerOrders(customer_id) {
      let criteria = {
        customer_id: customer_id ? customer_id : "",
      };
      let res = await searchCustomerOrders(criteria);
      return res.sort((a, b) => b.booking_id - a.booking_id);
    }

    getCustomerAppointments(customer?.customer_id).then((data) => {
      setAppointments(data);
    });

    getCustomerOrders(customer?.customer_id).then((data) => {
      let o = {};
      let allOrders = [];
      data.map((order) => {
        if (!o[order.booking_id]) {
          order.booked_dttm = moment(order.booked_dttm).format("LL");
          allOrders.push(order);
          o[order.booking_id] = true;
        }
      });
      setOrders(allOrders);
    });

    setFirstName(customer?.customer_name?.split(" ")[0]);
    setLastName(customer?.customer_name?.split(" ")[1]);
    setEmailAddress(customer?.customer_email);
    setPhoneNumber(customer?.customer_phone);
    setAddress1(customer?.customer_addr1);
    setAddress2(customer?.customer_addr2);
    setCity(customer?.customer_city);
    setCountry(
      customer?.customer_country ? customer?.customer_country : country,
    );
    setPostCode(customer?.customer_zip);
    setBirthdate(
      moment(
        customer?.customer_birth_dt ? customer?.customer_birth_dt : new Date(),
      )
        .utc()
        .format(),
    );
  }, [customer]);

  // Radar
  useEffect(() => {
    Radar.initialize(process.env.REACT_APP_RADAR_PK);

    try {
      // autocompleteRef1.current = Radar.ui.autocomplete({
      //   container: "autocomplete_address",
      //   width: "1200px",
      //   onSelection: (address) => {
      //     console.log(address);
      //     if (address.addressLabel) {
      //       address1Change({ target: { value: address.addressLabel } });
      //     }
      //
      //     if (address.city) {
      //       cityChange({ target: { value: address.city } });
      //     }
      //
      //     if (address.postalCode) {
      //       postCodeChange({ target: { value: address.postalCode } });
      //     }
      //   },
      // });

      autocompleteRef2.current = Radar.ui.autocomplete({
        container: "autocomplete_city",
        width: "1200px",
        onSelection: (address) => {
          console.log(address);
          if (address.city) {
            cityChange({ target: { value: address.city } });
          }

          if (address.postalCode) {
            postCodeChange({ target: { value: address.postalCode } });
          }
        },
      });

      autocompleteRef3.current = Radar.ui.autocomplete({
        container: "autocomplete_postal",
        width: "1200px",
        onSelection: (address) => {
          console.log(address);
          if (address.city) {
            cityChange({ target: { value: address.city } });
          }

          if (address.postalCode) {
            postCodeChange({ target: { value: address.postalCode } });
          }
        },
      });
    } catch (err) {}

    return () => {
      autocompleteRef1.current?.remove();
      autocompleteRef2.current?.remove();
      autocompleteRef3.current?.remove();
    };
  }, []);

  // Set Initial Data
  useEffect(() => {
    const samplePrescriptions = [
      {
        name: "Rampiril 5mg Capsules",
        quantity: "30 capsules",
        instructions: "Take one daily",
        lastDispensed: "March 31st, 2024",
      },
      {
        name: "Aspirin 81mg tablets",
        quantity: "30 tablets",
        instructions: "Take one daily",
        lastDispensed: "March 31st, 2024",
      },
      {
        name: "Ibuprofen 600mg tablets",
        quantity: "60 tablets",
        instructions: "Take one daily",
        lastDispensed: "January 31st, 2024",
      },
    ];
    setPrescriptions(samplePrescriptions);
  }, [customer]);

  const handleAppointmentSelection = (item) => {
    if (item?.appointment_metadata?.fundingType === "nhs") {
      item.srvc_ttl_amt = 0;
    }
    setShow(true);
    setSelectedAppointment(item);
  };

  const handleOrderSelection = (item) => {
    // Pull Order Information
    getOrderBooking(item.booking_id).then((result) => {
      setSelectedOrder(result);
    });
    // Set Show to True
    setShowOrderInvoice(true);
  };

  // Functions
  const firstNameChange = (e) => {
    setFirstName(e.target.value);
    if (!e.target.value) {
      setFirstNameValidation({
        valid: false,
        message: "First Name Is Required",
      });
    } else {
      setFirstNameValidation({ valid: true, message: "" });
    }
  };
  const lastNameChange = (e) => {
    setLastName(e.target.value);
    if (!e.target.value || e.target.value === firstName) {
      setLastNameValidation({
        valid: false,
        message: "Unique last Name Is Required",
      });
    } else {
      setLastNameValidation({ valid: true, message: "" });
    }
  };
  const phoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (phoneValidator(e.target.value)) {
      setPhoneNumberValidation({ valid: true, message: "" });
    } else {
      setPhoneNumberValidation({
        valid: false,
        message: "Please Enter Valid Phone Number of format 01111111111",
      });
    }
  };
  const emailAddressChange = (e) => {
    setEmailAddress(e.target.value);
    if (emailValidator(e.target.value)) {
      setEmailValidation({ valid: true, message: "" });
    } else {
      setEmailValidation({
        valid: false,
        message: "Please Enter Valid Email Address",
      });
    }
  };
  const address1Change = (e) => {
    setAddress1(e.target?.value);
    if (!e.target?.value) {
      setAddressValidaton({
        valid: false,
        message: "Address Line 1 Is Required",
      });
    } else {
      setAddressValidaton({ valid: true, message: "" });
    }
  };
  const address2Change = (e) => {
    setAddress2(e.target.value);
  };
  const cityChange = (e) => {
    setCity(e.target?.value);
    if (!e.target?.value) {
      setCityValidation({
        valid: false,
        message: "City Is Required",
      });
    } else {
      setCityValidation({ valid: true, message: "" });
    }
  };
  const postCodeChange = (e) => {
    setPostCode(e.target?.value);
    if (postalCodeValidator(e.target?.value)) {
      setPostalCodeValidation({ valid: true, message: "" });
    } else {
      setPostalCodeValidation({
        valid: false,
        message: "Please Enter Valid Postal Code",
      });
    }
  };

  const handleBirthdateDate = (date) => {
    // When Selecting Date, ignore time zone by doing start of day
    if (date) {
      date = moment(date).startOf("day").format();
      console.log(date);
      setBirthdate(date);
    }
    if (birthdateValidator(date)) {
      setBirthdateValidation({ valid: true, message: "" });
    } else {
      setBirthdateValidation({
        valid: false,
        message: "Please Enter Valid Birthdate",
      });
    }
  };
  const selectCountry = (e) => {
    // Set Value
    setCountry(e.target.value);
  };

  const handleSubmit = () => {
    console.log(moment(birthdate).utc().format("YYYY-MM-DD"));
    console.log(moment(birthdate).utc().format());
    let payload = {
      first_nm: firstName,
      last_nm: lastName,
      customer_phone: phoneNumber,
      customer_email: emailAddress,
      customer_birth_dt: moment(birthdate).utc().format("YYYY-MM-DD"),
      customer_addr1: address1,
      customer_addr2: address2,
      customer_city: city,
      customer_zip: postCode,
      customer_country: country,
      user_id: customer?.user_id,
    };

    let customerId = customer?.customer_id;
    //
    // // Update Customer
    updateCustomer(customerId, payload).then((result) => {
      console.log(result.data);
      replaceCustomerData(customerId);
      setActiveIndex(1);
      setEditProfile(false);
    });
  };

  const replaceCustomerData = (customerId) => {
    getCustomer(customerId).then((response) => {
      if (response.data) {
        setCustomerData(response.data);
      }
    });
  };

  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100 h-50"
        style={{
          backgroundImage: `url(assets/images/bg/postLogin.avif)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          width: 500,
          height: 500,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <section className="bg-hero">
        <div className="container">
          <div className="row mt-lg-5">
            <div className="col-md-6 col-lg-4">
              <div className="rounded shadow overflow-visible sticky-bar">
                <div className="card border-0">
                  <img
                    src="url(assets/images/bg/bg-profile.jpg)"
                    className="img-fluid"
                    alt=""
                  />
                </div>

                <div className="text-center avatar-profile margin-nagative mt-n5 position-relative pb-4 border-bottom">
                  <img
                    src={auth0User?.picture ? auth0User?.picture : ""}
                    className="rounded-circle shadow-md avatar avatar-md-md"
                    alt=""
                  />
                  <h5 className="mt-3 mb-1">{customer?.customer_name}</h5>
                  {/*<p className="text-muted mb-0">25 Years old</p>*/}
                </div>

                <div className="list-unstyled p-4">
                  <div className="progress-box mb-4">
                    <h6 className="title fw-bolder">Your Profile</h6>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">
                          First Name
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="First Name :"
                          value={firstName}
                          disabled={!editProfile}
                          onChange={firstNameChange}
                        />
                        {!firstNameValidation.valid ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            {" "}
                            {firstNameValidation.message}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">
                          Last Name
                        </label>
                        <input
                          name="name"
                          id="name2"
                          type="text"
                          className="form-control"
                          placeholder="Last Name :"
                          value={lastName}
                          disabled={!editProfile}
                          onChange={lastNameChange}
                        />
                        {!lastNameValidation.valid ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            {" "}
                            {lastNameValidation.message}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">
                          Your Email
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Your email :"
                          value={emailAddress}
                          disabled={!editProfile}
                          onChange={emailAddressChange}
                        />
                        {!emailValidation.valid ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            {" "}
                            {emailValidation.message}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">
                          Phone no.
                        </label>
                        <input
                          name="number"
                          id="number"
                          type="text"
                          className="form-control"
                          placeholder="Phone no. :"
                          value={phoneNumber}
                          disabled={!editProfile}
                          onChange={phoneNumberChange}
                        />
                        {!phoneNumberValidation.valid ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            {" "}
                            {phoneNumberValidation.message}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">
                          Birthdate
                        </label>
                        <div className={"form-control text-start w-100"}>
                          <DatePicker
                            className={"border-0 w-100 text-start"}
                            placeholderText="ddmmyyyy"
                            dateFormat={[
                              "dd MMMM, yyyy",
                              "dd-MM-yyyy",
                              "ddMMyyyy",
                              "dd/MM/yyyy",
                            ]}
                            locale="en-GB"
                            selected={birthdate}
                            maxDate={new Date()}
                            onChange={handleBirthdateDate}
                            placeholder={"ddMMYYYY"}
                            autoFocus={false}
                            disabled={!editProfile}
                            style={{ position: "absolute" }}
                          />
                          {!birthdateValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {birthdateValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/*Address Line 1*/}
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">
                          Address Line 1
                        </label>
                        <input
                          name="number"
                          id="autocomplete_address"
                          type="text"
                          className="form-control"
                          placeholder="Address Line 1 :"
                          value={address1}
                          disabled={!editProfile}
                          onChange={address1Change}
                        />
                        {!addressValidation.valid ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            {" "}
                            {addressValidation.message}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {/*Postal Code*/}
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">
                          Postal Code
                        </label>
                        <input
                          name="number"
                          id="autocomplete_postal"
                          type="text"
                          className="form-control"
                          placeholder="Post Code:"
                          value={postCode}
                          disabled={!editProfile}
                          onChange={postCodeChange}
                        />
                        {!postalCodeValidation.valid ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            {" "}
                            {postalCodeValidation.message}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {/*City*/}
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">City</label>
                        <input
                          name="number"
                          id="autocomplete_city"
                          type="text"
                          className="form-control"
                          placeholder="City:"
                          value={city}
                          disabled={true}
                          onChange={cityChange}
                        />
                        {!cityValidation.valid ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "smaller" }}
                          >
                            {" "}
                            {cityValidation.message}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {/*Country*/}
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label fw-bolder">Country</label>
                        <input
                          name="country"
                          id="country"
                          type="tel"
                          className="form-control"
                          required={true}
                          value={country}
                          disabled={true}
                          // placeholder="Postal Code: *"
                          // onChange={postCodeChange}
                        />
                      </div>
                    </div>

                    {/*Address Line 2*/}
                    {/*<div className="col-lg-6">*/}
                    {/*  <div className="mb-3">*/}
                    {/*    <label className="form-label fw-bolder">*/}
                    {/*      Address Line 2*/}
                    {/*    </label>*/}
                    {/*    <input*/}
                    {/*      name="number"*/}
                    {/*      id="number"*/}
                    {/*      type="text"*/}
                    {/*      className="form-control"*/}
                    {/*      placeholder="Address Line 2 :"*/}
                    {/*      value={address2}*/}
                    {/*      disabled={!editProfile}*/}
                    {/*      onChange={address2Change}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>

                  {/*Edit*/}
                  {!editProfile ? (
                    <div className="mt-3 col-12">
                      <input
                        type="button"
                        id="submit"
                        name="send"
                        className="btn btn-primary"
                        value="Edit"
                        onClick={() => setEditProfile(true)}
                      />
                    </div>
                  ) : (
                    <div className="mt-3 flex-row flex-0">
                      <div className="mt-3 col-12">
                        <input
                          type="button"
                          id="submit"
                          name="send"
                          className="btn btn-danger col-4 mx-2"
                          value="Cancel"
                          onClick={() => setEditProfile(false)}
                        />

                        <input
                          type="button"
                          id="submit"
                          name="send"
                          className="btn btn-primary col-4 mx-2"
                          value="Save"
                          disabled={!validForm}
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="card border-0 shadow overflow-hidden">
                {/*Practitioner and Pharmacy Buttons*/}
                <div className="tab-content p-4">
                  {/*Tabs*/}
                  <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-0 shadow overflow-hidden bg-light mt-3 mb-0">
                    <li className="nav-item">
                      <button
                        className={`${
                          activeTableIndex === 1 ? "active" : ""
                        } nav-link rounded-0`}
                        onClick={() => setActiveTableIndex(1)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">Appointments</h6>
                        </div>
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className={`${
                          activeTableIndex === 2 ? "active" : ""
                        } nav-link rounded-0`}
                        onClick={() => setActiveTableIndex(2)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">Orders</h6>
                        </div>
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className={`${
                          activeTableIndex === 3 ? "active" : ""
                        } nav-link rounded-0`}
                        onClick={() => setActiveTableIndex(3)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">Live Well</h6>
                        </div>
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className={`${
                          activeTableIndex === 4 ? "active" : ""
                        } nav-link rounded-0`}
                        onClick={() => setActiveTableIndex(4)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">
                            Medical Information
                          </h6>
                        </div>
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className={`${
                          activeTableIndex === 5 ? "active" : ""
                        } nav-link rounded-0`}
                        onClick={() => setActiveTableIndex(5)}
                      >
                        <div className="text-center pt-1 pb-1">
                          <h6 className="title fw-bolder mb-0">Mind Plan</h6>
                        </div>
                      </button>
                    </li>
                  </ul>

                  {/*Appointments*/}
                  {activeTableIndex === 1 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-12 mt-4">
                          {appointments.map((item, index) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center rounded p-3 shadow mt-3"
                                key={index}
                              >
                                <RiMicroscopeFill className="h3 fw-normal text-success mb-0" />
                                <div className="flex-1 overflow-hidden ms-2">
                                  <h6 className="mb-0">{item.srvc_desc}</h6>
                                  <p className="text-muted mb-0 text-truncate small">
                                    {item.appointment_type}{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      {moment(
                                        item.appointment_start_dttm,
                                      ).format("MMM Do YYYY")}
                                    </span>
                                  </p>
                                </div>
                                <Link
                                  to=""
                                  className="btn btn-icon btn-primary"
                                  onClick={() => {
                                    handleAppointmentSelection(item);
                                  }}
                                >
                                  <LuClipboardList />
                                </Link>
                              </div>
                            );
                          })}
                        </div>

                        <Modal
                          show={show}
                          onHide={() => setShow(false)}
                          size="lg"
                          centered
                        >
                          {/*<Modal.Header closeButton>*/}
                          {/*  <Modal.Title className="h5">*/}
                          {/*    Patient Invoice*/}
                          {/*  </Modal.Title>*/}
                          {/*</Modal.Header>*/}
                          <Modal.Body>
                            <div className="modal-body mx-0 px-0 pt-2 pt-sm-0">
                              <div className="container">
                                <div className="row mt-5 justify-content-center">
                                  <div className="col-lg-12">
                                    <div className="card border-0 rounded shadow px-4 py-5">
                                      <div className="row-mb-0">
                                        <div className="col">
                                          <img
                                            src={"assets/images/logo-icon.png"}
                                            height="75"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="row mb-4">
                                        <div className="col-lg-8 col-md-6">
                                          {/*<img*/}
                                          {/*  src={"/assets/images/logo-icon.png"}*/}
                                          {/*  height="75"*/}
                                          {/*  alt=""*/}
                                          {/*/>*/}
                                          <h6 className="mt-0 pt-0">
                                            Appointment Booking :
                                          </h6>
                                          <h6 className="mt-1 pt-2">
                                            {selectedAppointment?.appointment_metadata?.organization.organizationName.toUpperCase()}
                                          </h6>
                                          {/*<h6 className="mt-1 pt-2">Address :</h6>*/}
                                          <small className="text-muted mb-0">
                                            {selectedAppointment?.appointment_metadata?.organization.address1.toUpperCase()}
                                            , <br />
                                            {selectedAppointment
                                              ?.appointment_metadata
                                              ?.organization.address2
                                              ? selectedAppointment?.address2.toUpperCase()
                                              : null}
                                            {selectedAppointment?.appointment_metadata?.organization?.city.toUpperCase()}
                                            ,{" "}
                                            {`${selectedAppointment?.appointment_metadata?.organization?.county?.toUpperCase()} ${selectedAppointment?.appointment_metadata?.organization?.postCode.toUpperCase()}`}
                                          </small>
                                        </div>

                                        <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                          <ul className="list-unstyled">
                                            <li className="d-flex ms-0">
                                              <small className="mb-0 text-muted">
                                                Invoice no. :{" "}
                                              </small>
                                              <small className="mb-0 text-dark">
                                                &nbsp;&nbsp;
                                                {
                                                  selectedAppointment?.booking_id
                                                }
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Email :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                <Link
                                                  to={`mailto:${selectedAppointment?.appointment_metadata?.emailAddress}`}
                                                  className="text-dark"
                                                >
                                                  {
                                                    selectedAppointment
                                                      ?.appointment_metadata
                                                      ?.emailAddress
                                                  }
                                                </Link>
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Phone :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                <Link
                                                  to="tel:+152534-468-854"
                                                  className="text-dark"
                                                >
                                                  {
                                                    selectedAppointment
                                                      ?.appointment_metadata
                                                      ?.phoneNumber
                                                  }
                                                </Link>
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Website :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                <Link
                                                  to=""
                                                  className="text-dark"
                                                >
                                                  www.ziplanerx.com
                                                </Link>
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Patient Name :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                {`${selectedAppointment?.appointment_metadata.firstName} ${selectedAppointment?.appointment_metadata.lastName}`}
                                              </small>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="pt-4 border-top">
                                        <div className="row">
                                          <div className="col-lg-8 col-md-6">
                                            <h5 className="text-muted fw-bold">
                                              Invoice{" "}
                                              <span className="badge rounded-pill bg-soft-success fw-normal ms-2">
                                                {selectedAppointment
                                                  ?.appointment_metadata
                                                  ?.fundingType === "nhs"
                                                  ? ""
                                                  : "Paid"}
                                              </span>
                                            </h5>
                                            <h6>
                                              {selectedAppointment?.srvc_desc}
                                            </h6>
                                          </div>

                                          <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <ul className="list-unstyled">
                                              <li className="d-flex mt-2 ms-0">
                                                <small className="mb-0 text-muted">
                                                  Issue Dt. :{" "}
                                                </small>
                                                <small className="mb-0 text-dark">
                                                  &nbsp;&nbsp;
                                                  {moment(
                                                    selectedAppointment?.appointment_booked_dttm,
                                                  ).format("MMM Do YYYY")}
                                                </small>
                                              </li>

                                              <li className="d-flex mt-2 ms-0">
                                                <small className="mb-0 text-muted">
                                                  Appointment Dt. :{" "}
                                                </small>
                                                <small className="mb-0 text-dark">
                                                  &nbsp;&nbsp;
                                                  {moment(
                                                    selectedAppointment?.appointment_start_dttm,
                                                  ).format("MMM Do YYYY")}
                                                </small>
                                              </li>

                                              <li className="d-flex mt-2 ms-0">
                                                <small className="mb-0 text-muted">
                                                  Start Time. :{" "}
                                                </small>
                                                <small className="mb-0 text-dark">
                                                  &nbsp;&nbsp;
                                                  {moment(
                                                    selectedAppointment?.appointment_start_dttm,
                                                  ).format("hh:mm a")}
                                                </small>
                                              </li>

                                              <li className="d-flex mt-2 ms-0">
                                                <small className="mb-0 text-muted">
                                                  End Time. :{" "}
                                                </small>
                                                <small className="mb-0 text-dark">
                                                  &nbsp;&nbsp;
                                                  {moment(
                                                    selectedAppointment?.appointment_end_dttm,
                                                  ).format("hh:mm a")}
                                                </small>
                                              </li>

                                              {/*<li className="d-flex mt-2 ms-0">*/}
                                              {/*  <small className="mb-0 text-muted">Dr. Name : </small>*/}
                                              {/*  <small className="mb-0 text-dark">*/}
                                              {/*    &nbsp;&nbsp;Dr. Calvin Carlo*/}
                                              {/*  </small>*/}
                                              {/*</li>*/}
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="invoice-table pb-4">
                                          <div className="table-responsive shadow rounded mt-4">
                                            <table className="table table-center invoice-tb mb-0">
                                              <thead>
                                                <tr>
                                                  <th
                                                    scope="col"
                                                    className="text-start border-bottom p-3"
                                                    style={{ minWidth: "60px" }}
                                                  >
                                                    No.
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-start border-bottom p-3"
                                                    style={{
                                                      minWidth: "200px",
                                                    }}
                                                  >
                                                    Item
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="text-center border-bottom p-3"
                                                    style={{ minWidth: "60px" }}
                                                  >
                                                    Qty
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="border-bottom p-3"
                                                    style={{
                                                      minWidth: "130px",
                                                    }}
                                                  >
                                                    Rate
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="border-bottom p-3"
                                                    style={{
                                                      minWidth: "130px",
                                                    }}
                                                  >
                                                    Total
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th
                                                    scope="row"
                                                    className="text-start p-3"
                                                  >
                                                    1
                                                  </th>
                                                  <td className="text-start p-3">
                                                    {
                                                      selectedAppointment?.srvc_desc
                                                    }
                                                  </td>
                                                  <td className="text-center p-3">
                                                    1
                                                  </td>
                                                  <td className="p-3">
                                                    {
                                                      selectedAppointment?.srvc_ttl_amt
                                                    }
                                                  </td>
                                                  <td className="p-3">
                                                    {
                                                      selectedAppointment?.srvc_ttl_amt
                                                    }
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-4 col-md-5 ms-auto">
                                              <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                                                <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                                                  Subtotal :
                                                  <span>
                                                    {
                                                      selectedAppointment?.srvc_ttl_amt
                                                    }
                                                  </span>
                                                </li>
                                                <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                                                  Vat % :<span> 0</span>
                                                </li>
                                                <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                                                  Discount % :<span> 0</span>
                                                </li>
                                                <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                                                  Taxes :<span> 0</span>
                                                </li>
                                                <li className="d-flex justify-content-between pe-3 ms-0">
                                                  Total :
                                                  <span>
                                                    {
                                                      selectedAppointment?.srvc_ttl_amt
                                                    }
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="border-top pt-4">
                                          <div className="row">
                                            <div className="col-sm-6">
                                              <div className="text-sm-start text-muted text-center">
                                                <small className="mb-0">
                                                  Customer Service :{" "}
                                                  <Link
                                                    to="tel:+152534-468-854"
                                                    className="text-warning"
                                                  >
                                                    contact@ziplanerx.com
                                                  </Link>
                                                </small>
                                              </div>
                                            </div>
                                            <div className="col-sm-6">
                                              <div className="text-sm-end text-muted text-center">
                                                <small className="mb-0">
                                                  <Link
                                                    to="/aboutus/terms"
                                                    className="text-primary"
                                                  >
                                                    Terms & Conditions
                                                  </Link>
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>

                      {/*<h5 className="mb-0 mt-4 pt-2">Contact us</h5>*/}
                      {/*<div className="row">*/}
                      {/*  {adminFeature.slice(0, 2).map((item, index) => {*/}
                      {/*    let Icon = item.icon;*/}
                      {/*    return (*/}
                      {/*      <div*/}
                      {/*        className="col-md-12 col-lg-6 mt-4"*/}
                      {/*        key={index}*/}
                      {/*      >*/}
                      {/*        <div className="card features feature-primary text-center border-0 p-4 rounded shadow">*/}
                      {/*          <div className="icon text-center rounded-lg mx-auto">*/}
                      {/*            <Icon className="align-middle h3" />*/}
                      {/*          </div>*/}
                      {/*          <div className="card-body p-0 mt-3">*/}
                      {/*            <Link*/}
                      {/*              to="#"*/}
                      {/*              className="title text-dark h6 d-block"*/}
                      {/*            >*/}
                      {/*              {item.title}*/}
                      {/*            </Link>*/}
                      {/*            <Link to="#" className="link">*/}
                      {/*              Read more{" "}*/}
                      {/*              <FiArrowRight className="ri-arrow-right-line align-middle" />*/}
                      {/*            </Link>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    );*/}
                      {/*  })}*/}
                      {/*</div>*/}
                    </div>
                  ) : (
                    ""
                  )}

                  {/*Orders*/}
                  {activeTableIndex === 2 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-12 mt-4">
                          {orders?.map((item, index) => {
                            console.log(item);
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center rounded p-3 shadow mt-3"
                                key={index}
                              >
                                <RiInkBottleFill className="h3 fw-normal text-success mb-0" />
                                <div className="flex-1 overflow-hidden">
                                  <h6 className="flex-1 mb-0">
                                    Booking Id: # {item.booking_id}
                                  </h6>
                                  <p className="text-muted mb-0 text-truncate small fw-bold">
                                    {item.booked_dttm}
                                  </p>
                                </div>
                                <Link
                                  to="#"
                                  className="btn btn-icon btn-primary"
                                  onClick={() => {
                                    handleOrderSelection(item);
                                  }}
                                >
                                  <LuClipboardList />
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                        <Modal
                          show={showOrderInvoice}
                          onHide={() => setShowOrderInvoice(false)}
                          size="lg"
                          centered
                        >
                          <Modal.Body>
                            <div className="modal-body mx-0 px-0 pt-2 pt-sm-0">
                              <div className="container">
                                <div className="row mt-5 justify-content-center">
                                  <div className="col-lg-12">
                                    <div className="card border-0 rounded shadow mx-0 px-4 py-5">
                                      <div className="row-mb-0">
                                        <div className="col">
                                          <img
                                            src={"assets/images/logo-icon.png"}
                                            height="75"
                                            alt=""
                                          />
                                          <h6 className="mt-0 pt-0 text-primary">
                                            ZipLaneRx
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="row mb-4">
                                        <div className="col-lg-8 col-md-6">
                                          <h6 className="mt-1 pt-2">
                                            Pick Up Address
                                          </h6>
                                          <ul className="list-unstyled">
                                            <li className="d-flex ms-0">
                                              <small className="mb-0 text-muted">
                                                Mango Pharmacy
                                                {/*{data?.addressLine1}{" "}*/}
                                                {/*{data?.addressLine2 ? "," + data.addressLine2 : ""}*/}
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0">
                                              <small className="mb-0 text-muted">
                                                98 High Street,
                                                {/*{*/}
                                                {/*  selectedOrder[0]*/}
                                                {/*    ?.order_metadata*/}
                                                {/*    ?.addressLine1*/}
                                                {/*}{" "}*/}
                                                {/*{selectedOrder[0]*/}
                                                {/*  ?.order_metadata.addressLine2*/}
                                                {/*  ? "," +*/}
                                                {/*    selectedOrder[0]*/}
                                                {/*      .order_metadata*/}
                                                {/*      .addressLine2*/}
                                                {/*  : ""}*/}
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0">
                                              <small className="mb-0 text-muted">
                                                Edgware
                                                {/*{*/}
                                                {/*  selectedOrder[0]*/}
                                                {/*    ?.order_metadata.cityName*/}
                                                {/*}*/}
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0">
                                              <small className="mb-0 text-muted">
                                                United Kingdom, HA8 7HF
                                                {/*{*/}
                                                {/*  selectedOrder[0]*/}
                                                {/*    ?.order_metadata.countryName*/}
                                                {/*}*/}
                                                {/*,{" "}*/}
                                                {/*{*/}
                                                {/*  selectedOrder[0]*/}
                                                {/*    ?.order_metadata.postalCode*/}
                                                {/*}*/}
                                              </small>
                                            </li>
                                          </ul>
                                        </div>

                                        <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                          <ul className="list-unstyled">
                                            <li className="d-flex ms-0">
                                              <small className="mb-0 text-muted">
                                                Invoice no. :{" "}
                                              </small>
                                              <small className="mb-0 text-dark">
                                                &nbsp;&nbsp;
                                                {selectedOrder[0]?.booking_id ||
                                                  1837}
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Email :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                <Link
                                                  to="mailto:contact@example.com"
                                                  className="text-dark"
                                                >
                                                  {selectedOrder[0]
                                                    ?.order_metadata
                                                    .emailAddress ||
                                                    "contact@ziplanerx.com"}
                                                </Link>
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Phone :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                <Link
                                                  to="tel:+152534-468-854"
                                                  className="text-dark"
                                                >
                                                  {selectedOrder[0]
                                                    ?.order_metadata
                                                    ?.phoneNumber ||
                                                    "6095589535"}
                                                </Link>
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Website :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                <Link
                                                  to=""
                                                  className="text-dark"
                                                >
                                                  www.ziplanerx.com
                                                </Link>
                                              </small>
                                            </li>
                                            <li className="d-flex ms-0 mt-2">
                                              <small className="mb-0 text-muted">
                                                Patient Name :{" "}
                                              </small>
                                              <small className="mb-0">
                                                &nbsp;&nbsp;
                                                {`${
                                                  selectedOrder[0]
                                                    ?.order_metadata?.firstName
                                                    ? selectedOrder[0]
                                                        .order_metadata
                                                        .firstName
                                                    : "John"
                                                } ${
                                                  selectedOrder[0]
                                                    ?.order_metadata?.lastName
                                                    ? selectedOrder[0]
                                                        ?.order_metadata
                                                        ?.lastName
                                                    : "Doe"
                                                }`}
                                              </small>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="pt-4 border-top">
                                        <div className="row">
                                          <div className="col-lg-8 col-md-6">
                                            <h5 className="text-muted fw-bold">
                                              Invoice{" "}
                                              <span className="badge rounded-pill bg-soft-success fw-normal ms-2">
                                                Paid
                                              </span>
                                            </h5>
                                            {/*<h6>{data?.serviceName}</h6>*/}
                                          </div>

                                          <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                            <ul className="list-unstyled">
                                              <li className="d-flex mt-2 ms-0">
                                                <small className="mb-0 text-muted">
                                                  Order Dt. :{" "}
                                                </small>
                                                <small className="mb-0 text-dark">
                                                  &nbsp;&nbsp;
                                                  {moment(
                                                    selectedOrder[0]
                                                      ?.booking_dttm,
                                                  ).format("MMM Do YYYY")}
                                                </small>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                        <div className="invoice-table pb-4">
                                          <div className="table-responsive shadow rounded mt-4">
                                            <table className="table table-center invoice-tb mb-0">
                                              <thead>
                                                <tr>
                                                  <th
                                                    className="border-bottom p-3"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    Product
                                                  </th>
                                                  <th
                                                    className="border-bottom text-center p-3"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    Price
                                                  </th>
                                                  <th
                                                    className="border-bottom text-center p-3"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    Qty
                                                  </th>
                                                  <th
                                                    className="border-bottom text-center p-3"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    VAT
                                                  </th>
                                                  <th
                                                    className="border-bottom text-end p-3"
                                                    style={{
                                                      minWidth: "150px",
                                                    }}
                                                  >
                                                    Total
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {selectedOrder?.map(
                                                  (item, index) => {
                                                    if (
                                                      item.product_quantity > 0
                                                    ) {
                                                      return (
                                                        <tr key={index}>
                                                          <td className="p-3">
                                                            <div className="d-flex align-items-center">
                                                              {/*<img*/}
                                                              {/*  src={`data:image/gif;base64,${item.image}`}*/}
                                                              {/*  className="img-fluid avatar avatar-small rounded shadow"*/}
                                                              {/*  style={{ width: 50, height: 50 }}*/}
                                                              {/*  alt=""*/}
                                                              {/*/>*/}
                                                              <h6 className="mb-0 ms-3">
                                                                <Link
                                                                  to={`/shop/products/${item.productId}`}
                                                                >
                                                                  {
                                                                    item.product_desc
                                                                  }
                                                                </Link>
                                                              </h6>
                                                            </div>
                                                          </td>
                                                          <td className="text-center p-3">
                                                            {item.product_cost_amt
                                                              ? `£ ${item.product_cost_amt}`
                                                              : 0}
                                                          </td>
                                                          <td className="text-center shop-list p-3">
                                                            {
                                                              item.product_quantity
                                                            }
                                                          </td>
                                                          <td className="text-center shop-list p-3">
                                                            {
                                                              item
                                                                .order_metadata
                                                                .productVat
                                                            }
                                                          </td>
                                                          <td className="text-end font-weight-bold p-3">
                                                            £{" "}
                                                            {
                                                              item.product_sub_ttl_amt
                                                            }
                                                          </td>
                                                        </tr>
                                                      );
                                                    } else {
                                                      return null;
                                                    }
                                                  },
                                                )}
                                              </tbody>
                                            </table>
                                          </div>

                                          <div className="row">
                                            <div className="col-lg-4 col-md-5 ms-auto">
                                              <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                                                <li className="d-flex justify-content-between pe-3 ms-0">
                                                  Delivery Fee :{" "}
                                                  <span>
                                                    £{" "}
                                                    {
                                                      selectedOrder[0]
                                                        ?.order_metadata
                                                        ?.deliveryFee
                                                    }
                                                  </span>
                                                </li>
                                                <li className="d-flex justify-content-between pe-3 ms-0">
                                                  Total :{" "}
                                                  <span>
                                                    £{" "}
                                                    {
                                                      selectedOrder[0]
                                                        ?.ttl_order_amt
                                                    }
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="border-top pt-4">
                                          <div className="row">
                                            <div className="col-sm-6">
                                              <div className="text-sm-start text-muted text-center">
                                                <small className="mb-0">
                                                  Customer Service :{" "}
                                                  <Link
                                                    to="tel:+152534-468-854"
                                                    className="text-warning"
                                                  >
                                                    contact@ziplanerx.com
                                                  </Link>
                                                </small>
                                              </div>
                                            </div>

                                            <div className="col-sm-6">
                                              <div className="text-sm-end text-muted text-center">
                                                <small className="mb-0">
                                                  <Link
                                                    to="/aboutus/terms"
                                                    className="text-primary"
                                                  >
                                                    Terms & Conditions
                                                  </Link>
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-end mt-4 pt-2">
                                      <Link
                                        to="/home"
                                        onClick={() =>
                                          window.open("/home", "_self")
                                        }
                                        className="btn btn-soft-primary d-print-none"
                                        style={{ margin: 5 }}
                                      >
                                        <RiHome2Line /> Home
                                      </Link>
                                      <Link
                                        to="#"
                                        onClick={() => window.print()}
                                        className="btn btn-soft-primary d-print-none"
                                        style={{ margin: 5 }}
                                      >
                                        <RiPrinterLine /> Print
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>

                      {/*<h5 className="mb-0 mt-4 pt-2">Contact us</h5>*/}
                      {/*<div className="row">*/}
                      {/*  {adminFeature.slice(0, 2).map((item, index) => {*/}
                      {/*    let Icon = item.icon;*/}
                      {/*    return (*/}
                      {/*      <div*/}
                      {/*        className="col-md-12 col-lg-6 mt-4"*/}
                      {/*        key={index}*/}
                      {/*      >*/}
                      {/*        <div className="card features feature-primary text-center border-0 p-4 rounded shadow">*/}
                      {/*          <div className="icon text-center rounded-lg mx-auto">*/}
                      {/*            <Icon className="align-middle h3" />*/}
                      {/*          </div>*/}
                      {/*          <div className="card-body p-0 mt-3">*/}
                      {/*            <Link*/}
                      {/*              to="#"*/}
                      {/*              className="title text-dark h6 d-block"*/}
                      {/*            >*/}
                      {/*              {item.title}*/}
                      {/*            </Link>*/}
                      {/*            <Link to="#" className="link">*/}
                      {/*              Read more{" "}*/}
                      {/*              <FiArrowRight className="ri-arrow-right-line align-middle" />*/}
                      {/*            </Link>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    );*/}
                      {/*  })}*/}
                      {/*</div>*/}
                    </div>
                  ) : (
                    ""
                  )}

                  {/*Live Well*/}
                  {activeTableIndex === 3 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-12 mt-4">
                          <iframe
                            title="NHS.UK Live Well widget"
                            src="https://developer.api.nhs.uk/widgets/live-well?uid=0a606110-cb64-11ed-9728-df1cf3a8a3ec"
                            width="100%"
                            height="400px"
                            style={{
                              border: "solid 1px #ccc",
                              maxWidth: "600px",
                            }}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/*Medical Information*/}
                  {activeTableIndex === 4 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-12 mt-4">
                          <iframe
                            title="NHS.UK Health A to Z widget"
                            src="https://developer.api.nhs.uk/widgets/conditions?uid=0a606110-cb64-11ed-9728-df1cf3a8a3ec"
                            width="100%"
                            height="400px"
                            style={{
                              border: "solid 1px #ccc",
                              maxWidth: "550px",
                            }}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/*Mind Plan*/}
                  {activeTableIndex === 5 ? (
                    <div className="tab-pane fade show active">
                      <div className="row">
                        <div className="col-12 mt-4">
                          <h3>Coming Soon!</h3>
                          <div
                            id="triage-tool"
                            data-site-id=""
                            data-syndication-id="0a606110-cb64-11ed-9728-df1cf3a8a3ec"
                          ></div>
                          <script src="https://assets.nhs.uk/triage-tool/triage-tool.js"></script>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
};

export default withAuth0(PatientProfile);
