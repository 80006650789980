// hooks/usePractitionerData.js
import { useEffect, useState } from "react";
import { getOverBookedSlots } from "../utils/sales.service";
import { getBlockedSlots } from "../utils/pctmgmt.services";
import moment from "moment";

const usePractitionerData = (practitioner, startDate) => {
  const [overbookedSlots, setOverbookedSlots] = useState([]);
  const [practitionerBlockedSlots, setPractitionerBlockedSlots] = useState([]);

  useEffect(() => {
    if (practitioner && startDate) {
      // Overbooked Slots
      getOverBookedSlots({
        p_nbr: practitioner.p_nbr,
        date: moment(startDate).format("YYYY-MM-DD"),
      }).then((res) => {
        if (res && res.length > 0) {
          setOverbookedSlots(res.map((item) => new Date(item)));
        } else {
          setOverbookedSlots([]);
        }
      });

      // Blocked Slots
      getBlockedSlots({
        p_nbr: practitioner.p_nbr,
        date: moment(startDate).format("YYYY-MM-DD"),
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          setPractitionerBlockedSlots(res.data.map((item) => new Date(item)));
        } else {
          setPractitionerBlockedSlots([]);
        }
      });
    }
  }, [practitioner, startDate]);

  return { overbookedSlots, practitionerBlockedSlots };
};

export default usePractitionerData;
