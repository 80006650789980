import { getConsentForm, getService } from "./referencedata.services";
import {
  getPractitionerById,
  getPractitionerService,
  getPractitionerStripeAccount,
} from "./pctmgmt.services";

export async function formServiceSearchData(serviceId, p_nbr) {
  let service;
  let serviceTemplate;
  let practitioner;
  let practitionerService;
  let practitionerStripe;
  let result;

  try {
    // Service
    let serviceResp = await getService(serviceId);
    service = serviceResp?.data;

    // Template
    if (service?.template_ind && service?.template_id) {
      let serviceTemplateResp = await getConsentForm(service?.template_id);
      serviceTemplate = serviceTemplateResp.data;
    }

    // Practitioner
    let practitionerResp = await getPractitionerById(p_nbr);
    practitioner = practitionerResp?.data;

    // Practitioner Service
    let practitionerServiceResp = await getPractitionerService(
      p_nbr,
      serviceId,
    );
    practitionerService = practitionerServiceResp?.data;

    // Practitioner Stripe
    let practitionerStripeResp = await getPractitionerStripeAccount(p_nbr);
    practitionerStripe = practitionerStripeResp?.data;

    result = {
      serviceName: service?.srvc_nm,
      serviceId: serviceId,
      isNhs: service?.nhs_in,
      practitionerData: practitioner,
      practitionerStripeAccount: practitionerStripe,
      service: practitionerService,
      consentTemplate: serviceTemplate,
    };

    return result;
  } catch (err) {
    console.log(err);
  }
}

export function formatDate(date) {
  // Get Year, month, date
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();

  // Format
  m = m < 10 ? `0${m.toString()}` : m.toString();
  d = d < 10 ? `0${d.toString()}` : d.toString();

  // Return
  return `${y}-${m}-${d}`;
}

export function capitalizeFirstLetter(string) {
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function formatAppointmentUrl(serviceType) {
  switch (serviceType) {
    case "GP":
      return "/book/services/gp#appointment";
    default:
      return "/book/services#appointment";
  }
}
