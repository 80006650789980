// components/DatePickerComponent.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./fields.css";

const DatePickerComponent = ({
  selectedDate,
  onChange,
  filterDate,
  minDate,
}) => (
  <div className="form-control mb-3 border-1 border rounded">
    <DatePicker
      className="border-0"
      placeholderText="Choose Appointment Date*"
      dateFormat="MMMM d, yyyy"
      selected={selectedDate}
      onChange={onChange}
      filterDate={filterDate}
      minDate={minDate}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    />
  </div>
);

export default DatePickerComponent;
