// components/InteractiveForm.js
import React, { useState } from "react";
import { FormControlLabel, FormGroup, styled, Switch } from "@mui/material";
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><text x="0" y="16" font-family="Arial" font-size="14" font-weight="bold" fill="white">Yes</text></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><text x="2" y="16" font-family="Arial" font-size="14" font-weight="bold" fill="white">No</text></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 17,
    height: 17,
    margin: 2,
  },
}));

const InteractiveForm = ({ question, id, handler }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    handler(id, isChecked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        required
        control={<Android12Switch checked={checked} onChange={handleChange} />}
        label={question}
        style={{ fontSize: "xx-small", textAlign: "start" }}
      />
    </FormGroup>
  );
};

export default InteractiveForm;
