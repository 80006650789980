import React from "react";
import "./services-search.css";

function SearchButton({ searchPharmacies }) {
  return (
    <div className="col-md-auto mt-2 mt-sm-0">
      <div className="d-grid d-md-block">
        <button
          type="button"
          onClick={searchPharmacies}
          className="btn btn-primary"
        >
          Search
        </button>
      </div>
    </div>
  );
}

export default SearchButton;
