import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import "tiny-slider/dist/tiny-slider.css";
import Header from "../../components/header/header";
import moment from "moment";
import { getOrder, postOrderPayment } from "../../utils/backoffice.service";
import ServiceInvoice from "../../components/invoice/service-invoice";
import { searchCustomerAppointments } from "../../utils/sales.service";

export default function ServiceInvoicePage() {
  // Variables
  const [searchParams, setSearchParams] = useSearchParams();
  let orderId = searchParams.get("orderId");
  let paymentIntentId = searchParams.get("payment_intent") || null;
  const [orderData, setOrderData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);

  // Use Effect
  useEffect(() => {
    if (orderId) {
      getOrder(orderId).then((response) => {
        if (response.data) {
          response.data.orderId = orderId;
          setOrderData(response.data);
        }
      });
    }
  }, [orderId]);

  useEffect(() => {
    // Search Appointments
    if (orderData) {
      // Search in MySql
      let criteria = { booking_id: orderId };
      searchCustomerAppointments(criteria)
        .then((res) => {
          if (res && res.length > 0) {
            setShowInvoice(true);
          } else {
            let payload = { ...orderData };
            payload.orderId = payload.id;
            payload.paymentIntentId = paymentIntentId;
            payload.id = null;
            postOrderPayment(payload)
              .then((response) => {
                if (response.data) {
                  setPaymentData(response.data);
                  setShowInvoice(true);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orderData]);

  return (
    <Fragment>
      <Header />
      <section className="bg-half-170 d-table w-100">
        <div
          className="bg-overlay bg-overlay-dark"
          style={{ backgroundColor: "#202942" }}
        >
          <div className="container">
            <div className="row mt-5 justify-content-center">
              <div className="col-12">
                <div className="section-title text-center">
                  <h3
                    className="sub-title mb-4 text-light"
                    style={{ marginTop: 100, opacity: 0.7 }}
                  >
                    {orderData?.firstName
                      ? `Here is your Invoice ${orderData.firstName}!`
                      : "Here is your Invoice!"}
                  </h3>
                  <p className="para-desc mx-auto text-white">via ZipLaneRx</p>

                  <ul className="list-unstyled mt-4">
                    <li className="list-inline-item date text-white">
                      <i className="mdi mdi-calendar-check"></i>{" "}
                      {moment(new Date()).format("MMM Do, YYYY")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {showInvoice && orderData ? (
                <ServiceInvoice data={orderData} id="invoice" />
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
