// components/TimePickerComponent.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./fields.css";

const TimePickerComponent = ({
  selectedTime,
  onChange,
  excludeTimes,
  minTime,
  maxTime,
}) => (
  <div className="form-control mb-3 border-1 border rounded">
    <DatePicker
      className="border-0"
      placeholderText="Choose Time*"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
      selected={selectedTime}
      onChange={onChange}
      excludeTimes={excludeTimes}
      minTime={minTime}
      maxTime={maxTime}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    />
  </div>
);

export default TimePickerComponent;
