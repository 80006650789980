// BookAppointmentForm.js
import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createPdfBlobUrl } from "../../../utils/document.utils";
import { emailValidator, phoneValidator } from "../../../utils/validators";
import { postOrder } from "../../../utils/backoffice.service";
import {
  createPractitionerInvoice,
  paymentIntentCallProxy,
} from "../../../utils/stripe.service";
import { orderDataPayload } from "../../../utils/payloads.utils";
import {
  servicesInteractiveForm,
  servicesSuitabilityForm,
} from "../../../data/data";

import InputField from "../../fields/InputField";
import DatePickerComponent from "../../fields/DatePickerComponent";
import TimePickerComponent from "../../fields/TimePickerComponent";
import ConsentForm from "./ConsentForm";
import InteractiveForm from "./InteractiveForm";
import PaymentForm from "./PaymentForm";
import useCustomerData from "../../../hooks/useCustomerData";
import usePractitionerData from "../../../hooks/usePractitionerData";
import { setHours, setMinutes } from "date-fns";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  serviceMethod: "",
  serviceRequestNotes: "",
};

function formReducer(state, action) {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
}

const BookAppointmentForm = ({ data, handleOrderData, handlePaymentData }) => {
  // State and Reducer
  const [formState, dispatch] = useReducer(formReducer, initialState);

  // Session and Customer Data
  const sessionCustomerId = sessionStorage.getItem("customerId");
  const [customerId] = useState(sessionCustomerId);
  const customer = useCustomerData(customerId);

  // Other State Variables
  const [practitioner, setPractitioner] = useState(null);
  const [service, setService] = useState(null);
  const [businessDays, setBusinessDays] = useState(null);
  const [businessStartTime, setBusinessStartTime] = useState(null);
  const [businessEndTime, setBusinessEndTime] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [startTimeFormatted, setStartTimeFormatted] = useState(null);
  const [ageConsent, setAgeConsent] = useState(false);
  const [consent, setConsent] = useState(false);
  const [informationConsent, setInformationConsent] = useState(false);
  const [showConsentLink, setShowConsentLink] = useState(false);
  const [consentForm, setConsentForm] = useState(null);
  const [showInformationLink, setShowInformationLink] = useState(false);
  const [informationLink, setInformationLink] = useState(null);

  const [interactiveForm, setInteractiveForm] = useState([]);
  const [interactiveFormData, setInteractiveFormData] = useState([]);
  const [showInteractiveForm, setShowInteractiveForm] = useState(false);

  const [serviceMethods, setServiceMethods] = useState([]);
  const [validForm, setValidForm] = useState(false);

  const [suitabilityForm, setSuitabilityForm] = useState([]);
  const [suitabilityFormData, setSuitabilityFormData] = useState([]);
  const [unSuitabilityForm, setUnSuitabilityForm] = useState([]);
  const [unSuitabilityFormData, setUnSuitabilityFormData] = useState([]);
  const [showSuitabilityForm, setShowSuitabilityForm] = useState(false);
  const [showUnSuitabilityForm, setShowUnSuitabilityForm] = useState(false);
  const [suitabilityError, setSuitabilityError] = useState(false);

  const [orderData, setOrderData] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeApiUp, setStripeApiUp] = useState(true);
  const [loadStripePayment, setLoadStripePayment] = useState(false);
  const [nhsPractitionerInvoice, setNhsPractitionerInvoice] = useState(null);

  // Validation States
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [emailValidation, setEmailValidation] = useState({
    valid: false,
    message: "",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    valid: false,
    message: "",
  });
  const [methodValidation, setMethodValidation] = useState({
    valid: false,
    message: "",
  });

  // Use custom hooks for practitioner data
  const { overbookedSlots, practitionerBlockedSlots } = usePractitionerData(
    practitioner,
    startDate,
  );

  // Pre-populate form with customer data
  useEffect(() => {
    if (customer) {
      if (customer?.user?.first_nm) {
        dispatch({
          type: "SET_FIELD",
          field: "firstName",
          value: customer?.user?.first_nm,
        });
        setFirstNameValidation({ valid: true, message: "" });
      }

      if (customer?.user?.last_nm) {
        dispatch({
          type: "SET_FIELD",
          field: "lastName",
          value: customer?.user?.last_nm,
        });
        setLastNameValidation({ valid: true, message: "" });
      }

      if (customer?.customer_email) {
        dispatch({
          type: "SET_FIELD",
          field: "emailAddress",
          value: customer?.customer_email,
        });
        setEmailValidation({
          valid: emailValidator(customer?.customer_email),
          message: "Please Enter Valid Email Address",
        });
      }

      if (customer?.customer_phone) {
        dispatch({
          type: "SET_FIELD",
          field: "phoneNumber",
          value: customer?.customer_phone,
        });
        setPhoneNumberValidation({
          valid: phoneValidator(customer.customer_phone),
          message: "Please Enter Valid Phone Number",
        });
      }
    }
  }, [customer]);

  // Update validity of the form
  useEffect(() => {
    setValidForm(
      practitioner &&
        service &&
        firstNameValidation.valid &&
        lastNameValidation.valid &&
        emailValidation.valid &&
        phoneNumberValidation.valid &&
        methodValidation.valid &&
        startDate &&
        startTime &&
        ageConsent &&
        !suitabilityError,
    );
  }, [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    phoneNumberValidation,
    methodValidation,
    startDate,
    startTime,
    suitabilityError,
    ageConsent,
    practitioner,
    service,
  ]);

  // Set service and practitioner data
  useEffect(() => {
    if (data && data.serviceSearchData) {
      setService(data.serviceSearchData.service);
      setPractitioner(data.serviceSearchData.practitionerData);
      setBusinessDays(
        data.serviceSearchData.practitionerData.business_days_json,
      );
      setBusinessStartTime(
        data.serviceSearchData.practitionerData.business_hours_start_tm_json,
      );
      setBusinessEndTime(
        data.serviceSearchData.practitionerData.business_hours_close_tm_json,
      );

      // Consent Form
      if (data.serviceSearchData.consentTemplate) {
        setShowConsentLink(true);
        setConsentForm(data.serviceSearchData.consentTemplate[0]);
      } else {
        setShowConsentLink(false);
      }

      // Service Methods
      const consultationTypes =
        data.serviceSearchData.service.consultation_type;
      const methods = Object.keys(consultationTypes).filter(
        (key) => consultationTypes[key],
      );
      setServiceMethods(methods);

      // Interactive Form
      const interactiveData =
        servicesInteractiveForm[data.serviceSearchData.serviceId.toLowerCase()];
      if (interactiveData && interactiveData.nhsConsentLink) {
        setShowInformationLink(true);
        setInformationLink(interactiveData.nhsConsentLink);
        setInteractiveForm(interactiveData.questionnaire);
        setInteractiveFormData(
          new Array(interactiveData.questionnaire.length).fill(false),
        );
        setShowInteractiveForm(interactiveData.questionnaire.length > 0);
      } else {
        setShowInformationLink(false);
        setShowInteractiveForm(false);
      }

      // Suitability Form
      const suitabilityData =
        servicesSuitabilityForm[data.serviceSearchData.serviceId.toLowerCase()];
      if (suitabilityData) {
        if (suitabilityData.suitable && suitabilityData.suitable.length > 0) {
          setShowSuitabilityForm(true);
          setSuitabilityForm(suitabilityData.suitable);
          setSuitabilityFormData(
            new Array(suitabilityData.suitable.length).fill(false),
          );
          setSuitabilityError(true);
        }
        if (
          suitabilityData.unsuitable &&
          suitabilityData.unsuitable.length > 0
        ) {
          setShowUnSuitabilityForm(true);
          setUnSuitabilityForm(suitabilityData.unsuitable);
          setUnSuitabilityFormData(
            new Array(suitabilityData.unsuitable.length).fill(false),
          );
        }
      }
    } else {
      // Reset state if no data
      setService(null);
      setPractitioner(null);
      setBusinessDays(null);
      setBusinessStartTime(null);
      setBusinessEndTime(null);
      setAgeConsent(false);
      setConsent(false);
      setInformationConsent(false);
      dispatch({ type: "SET_FIELD", field: "serviceMethod", value: "" });
      dispatch({ type: "SET_FIELD", field: "serviceRequestNotes", value: "" });
      setStartDate(new Date());
      setStartTime(null);
    }
  }, [data]);

  // Reload to Invoice
  useEffect(() => {
    if (orderData && nhsPractitionerInvoice) {
      window.open(
        `/book/services/invoice?orderId=${orderData.id}&payment_intent=${nhsPractitionerInvoice.id}`,
        "_self",
      );
    }
  }, [orderData, nhsPractitionerInvoice]);

  // Handlers for input fields
  const handleFieldChange = (field) => (e) => {
    dispatch({ type: "SET_FIELD", field, value: e.target.value });
    // Validation logic
    switch (field) {
      case "firstName":
        setFirstNameValidation({
          valid: !!e.target.value,
          message: "First Name Is Required",
        });
        break;
      case "lastName":
        setLastNameValidation({
          valid: !!e.target.value,
          message: "Last Name Is Required",
        });
        break;
      case "emailAddress":
        setEmailValidation({
          valid: emailValidator(e.target.value),
          message: "Please Enter Valid Email Address",
        });
        break;
      case "phoneNumber":
        setPhoneNumberValidation({
          valid: phoneValidator(e.target.value),
          message: "Please Enter Valid Phone Number of format 01111111111",
        });
        break;
      default:
        break;
    }
  };

  const handleServiceMethodChange = (e) => {
    dispatch({
      type: "SET_FIELD",
      field: "serviceMethod",
      value: e.target.value,
    });
    setMethodValidation({
      valid: !!e.target.value,
      message: "Method Is Required",
    });
  };

  const handleDateChange = (date) => {
    if (date) {
      // Get Business Hour Start:
      let startHour =
        businessStartTime && businessEndTime
          ? setHours(
              setMinutes(new Date(), 0),
              parseInt(
                getBusinessHoursForDay(
                  moment(startDate).format("dddd"),
                ).start.split(":")[0],
              ),
            )
          : setHours(startDate, 9);
      startHour = startHour.getHours() || 0;
      date = moment(date).hour(startHour).minute(0).toDate();
      setStartDate(date);
      handleTimeChange(date);
    }
  };

  const handleTimeChange = (time) => {
    let fmttime = moment(time).utc().format();
    setStartTime(time);
    setStartTimeFormatted(fmttime);
  };

  const isBusinessDay = (date) => {
    const dayName = moment(date).format("dddd");
    return businessDays ? businessDays[dayName] : true;
  };

  const getBusinessHoursForDay = (dayName) => {
    return {
      start: businessStartTime ? businessStartTime[dayName] : null,
      close: businessEndTime ? businessEndTime[dayName] : null,
    };
  };

  const getMinTime = () => {
    const dayName = moment(startDate).format("dddd");
    const startHour = getBusinessHoursForDay(dayName).start || "09:00";
    return moment(startHour, "HH:mm").toDate();
  };

  const getMaxTime = () => {
    const dayName = moment(startDate).format("dddd");
    const endHour = getBusinessHoursForDay(dayName).close || "17:00";
    return moment(endHour, "HH:mm").toDate();
  };

  const consentDownload = () => {
    createPdfBlobUrl(consentForm);
  };

  const interactiveFormHandler = (id, value) => {
    const updatedData = [...interactiveFormData];
    updatedData[id] = value;
    setInteractiveFormData(updatedData);
  };

  const suitabilityFormHandler = (id, value) => {
    const updatedData = [...suitabilityFormData];
    updatedData[id] = value;
    setSuitabilityFormData(updatedData);
    setSuitabilityError(!updatedData.includes(true));
  };

  const unsuitabilityFormHandler = (id, value) => {
    const updatedData = [...unSuitabilityFormData];
    updatedData[id] = value;
    setUnSuitabilityFormData(updatedData);
    setSuitabilityError(updatedData.includes(true));
  };

  const handleSubmit = () => {
    setSuitabilityError(false);
    let iFormData = [];
    if (interactiveFormData.length > 0) {
      interactiveForm.forEach((d, i) => {
        iFormData.push({ question: d.question, value: interactiveFormData[i] });
      });
    }
    if (suitabilityFormData.length > 0) {
      suitabilityForm.forEach((d, i) => {
        iFormData.push({ question: d.question, value: suitabilityFormData[i] });
      });
    }
    if (unSuitabilityFormData.length > 0) {
      unSuitabilityForm.forEach((d, i) => {
        iFormData.push({
          question: d.question,
          value: unSuitabilityFormData[i],
        });
      });
    }
    let payload = {
      ...formState,
      bookingDate: startDate,
      startTime: moment(startTime).utc().format(),
      endTime: moment(startTime)
        .add(data.serviceSearchData.service.srvc_duration_time, "minutes")
        .utc()
        .format(),
      interactiveFormData: iFormData,
      ...data.serviceSearchData,
      customer: customer,
    };
    payload = orderDataPayload(payload);

    postOrder(payload).then((r) => {
      setOrderData({ ...payload, ...r.data });
      if (!service?.nhs_in && service.srvc_price_amt > 0) {
        callStripeIntent({
          amount: data.serviceSearchData.service.srvc_price_amt,
          orderId: r.data.id,
          emailAddress: r.data.emailId,
          stripeConnectAccountId: data.serviceSearchData
            .practitionerStripeAccount
            ? data.serviceSearchData.practitionerStripeAccount.account_id
            : null,
        });
      } else if (service?.nhs_in || service.srvc_price_amt <= 0) {
        callStripeCreatePracitionerInvoice({
          amount: data.serviceSearchData.service.srvc_nhs_claims_amt,
          orderId: r.data.id,
          emailAddress: r.data.emailId,
          stripeCustomerId: data.serviceSearchData.practitionerStripeAccount
            ? data.serviceSearchData.practitionerStripeAccount.customer_id
            : null,
          serviceName: data.serviceSearchData.service.srvc_nm,
        });
      }
      handleOrderData({ ...payload, ...r.data });
    });
  };

  const callStripeIntent = (data) => {
    let payload = {
      amount: data.amount * 100,
      currency: "gbp",
      "automatic_payment_methods[enabled]": true,
      orderId: data.orderId,
      receipt_email: data.emailAddress,
      stripeConnectAccountId: data.stripeConnectAccountId,
      type: "appointment",
    };
    paymentIntentCallProxy(payload)
      .then((r) => {
        setClientSecret(r.data.client_secret);
        setLoadStripePayment(true);
        setStripeApiUp(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 503) {
          setStripeApiUp(false);
        }
      });
  };

  const callStripeCreatePracitionerInvoice = (data) => {
    let payload = {
      amount: data.amount * 100,
      stripeCustomerId: data.stripeCustomerId,
      description: `Booking ID: ${data.orderId} for ${data.emailAddress} | ${data.serviceName}`,
    };
    createPractitionerInvoice(payload)
      .then((r) => {
        setStripeApiUp(true);
        setNhsPractitionerInvoice(r.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 503) {
          setStripeApiUp(false);
        }
      });
  };

  const options = {
    clientSecret: clientSecret,
    loader: "always",
  };

  return (
    <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div
        className="card border-0 rounded shadow p-4 ms-xl-3"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="custom-form">
          <form>
            <div className="row">
              {/* Practitioner and Service Details */}
              <div className="col-lg-12">
                <InputField
                  value={
                    practitioner ? practitioner.business_nm : "Practitioner :"
                  }
                  placeholder="Practitioner :"
                  readOnly
                />
              </div>
              {service && service?.nhs_in ? (
                <div className="col-lg-12">
                  <InputField
                    value={service ? service.srvc_desc : "Service :"}
                    placeholder="Service :"
                    readOnly
                  />
                </div>
              ) : (
                <>
                  <div className="col-lg-6">
                    <InputField
                      value={service ? service.srvc_desc : "Service :"}
                      placeholder="Service :"
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputField
                      value={
                        service
                          ? `Service Cost: £ ${service.srvc_price_amt}`
                          : null
                      }
                      placeholder="Service Cost: £"
                      readOnly
                    />
                  </div>
                </>
              )}
              {/* Input Fields */}
              <div className="col-lg-6">
                <InputField
                  required
                  placeholder="Patient First Name :*"
                  value={formState.firstName}
                  onChange={handleFieldChange("firstName")}
                  validation={firstNameValidation}
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  required
                  placeholder="Patient Last Name :*"
                  value={formState.lastName}
                  onChange={handleFieldChange("lastName")}
                  validation={lastNameValidation}
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  type="email"
                  required
                  placeholder="Your email :*"
                  value={formState.emailAddress}
                  onChange={handleFieldChange("emailAddress")}
                  validation={emailValidation}
                />
              </div>
              <div className="col-lg-6">
                <InputField
                  type="tel"
                  required
                  placeholder="Your Phone* : 01223334444"
                  value={formState.phoneNumber}
                  onChange={handleFieldChange("phoneNumber")}
                  validation={phoneNumberValidation}
                />
              </div>
              {/* Date and Time Pickers */}
              <div className="col-lg-6">
                <DatePickerComponent
                  selectedDate={startDate}
                  onChange={handleDateChange}
                  filterDate={isBusinessDay}
                  minDate={new Date()}
                />
              </div>
              <div className="col-lg-6">
                <TimePickerComponent
                  selectedTime={startTime}
                  onChange={handleTimeChange}
                  excludeTimes={[
                    ...overbookedSlots,
                    ...practitionerBlockedSlots,
                  ]}
                  minTime={getMinTime()}
                  maxTime={getMaxTime()}
                />
              </div>
              {/* Service Method */}
              <div className="col-lg-12">
                <div className="mb-3">
                  <select
                    className="form-control"
                    value={formState.serviceMethod}
                    onChange={handleServiceMethodChange}
                  >
                    <option value="">Select Service Method*</option>
                    {serviceMethods.map((met) => (
                      <option key={met} value={met}>
                        {met}
                      </option>
                    ))}
                  </select>
                  {!methodValidation.valid && (
                    <span
                      className="text-danger"
                      style={{ fontSize: "smaller" }}
                    >
                      {methodValidation.message}
                    </span>
                  )}
                </div>
              </div>
              {/* Service Request Notes */}
              <div className="col-lg-12">
                <div className="mb-3">
                  <textarea
                    name="comments"
                    id="comments"
                    rows="4"
                    className="form-control"
                    placeholder="Your Notes :"
                    value={formState.serviceRequestNotes}
                    onChange={handleFieldChange("serviceRequestNotes")}
                    onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                  ></textarea>
                </div>
              </div>
              {/* Consent Form */}
              <div className="col-lg-12">
                <ConsentForm
                  ageConsent={ageConsent}
                  setAgeConsent={setAgeConsent}
                  consent={consent}
                  setConsent={setConsent}
                  showConsentLink={showConsentLink}
                  consentDownload={consentDownload}
                  informationConsent={informationConsent}
                  setInformationConsent={setInformationConsent}
                  showInformationLink={showInformationLink}
                  informationLink={informationLink}
                />
                {/* Interactive Forms */}
                {showInteractiveForm && (
                  <div>
                    <label
                      className="form-label"
                      style={{ fontWeight: "bold" }}
                    >
                      Please answer the following questions (Select all that
                      apply):
                      <span className="text-danger">*</span>
                    </label>
                    {interactiveForm.map((item, index) => (
                      <InteractiveForm
                        key={index}
                        question={item.question}
                        id={index}
                        handler={interactiveFormHandler}
                      />
                    ))}
                  </div>
                )}
                {/* Suitability Forms */}
                {(showSuitabilityForm || showUnSuitabilityForm) && (
                  <div>
                    <label
                      className="form-label"
                      style={{ fontWeight: "bold" }}
                    >
                      Please answer the following questions (Select all that
                      apply):
                      <span className="text-danger">*</span>
                    </label>
                    {unSuitabilityForm.map((item, index) => (
                      <InteractiveForm
                        key={index}
                        question={item.question}
                        id={index}
                        handler={unsuitabilityFormHandler}
                      />
                    ))}
                    {suitabilityForm.map((item, index) => (
                      <InteractiveForm
                        key={index}
                        question={item.question}
                        id={index}
                        handler={suitabilityFormHandler}
                      />
                    ))}
                    {suitabilityError && !orderData && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "medium" }}
                      >
                        Based on your answers, you are ineligible for this
                        service!
                      </span>
                    )}
                  </div>
                )}
              </div>
              {/* Submit Button */}
              <div className="col-lg-12">
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-primary fw-bolder"
                    disabled={
                      (showConsentLink && !consent) ||
                      (showInformationLink && !informationConsent) ||
                      (orderData && (service?.nhs_in || loadStripePayment)) ||
                      !validForm
                    }
                    onClick={handleSubmit}
                  >
                    {service?.nhs_in
                      ? "Book Your Free Service"
                      : "Proceed To Payment"}
                  </button>
                </div>
                {/* Display messages based on order status */}
                <div
                  className="col-lg-12 text-center mt-3"
                  style={{ fontSize: "medium" }}
                >
                  {orderData && nhsPractitionerInvoice ? null : orderData &&
                    !stripeApiUp ? (
                    <div className="d-grid">
                      <p>
                        <span className="fw-bolder text-danger">
                          Stripe API is down{" "}
                        </span>
                        <span className="text-primary">
                          {orderData.firstName}!
                        </span>
                      </p>
                      <p>
                        <span className="text-danger">
                          Please try again later!
                        </span>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Payment Form */}
      {orderData && loadStripePayment && !service?.nhs_in && (
        <div className="pt-0">
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm data={orderData} clientSecret={clientSecret} />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default BookAppointmentForm;
