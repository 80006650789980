import React from "react";
import { Grid, Switch } from "@mui/material";
import "./services-search.css";

function NhsToggleSwitch({ isNhs, handleChange }) {
  return (
    <Grid
      className="col-md"
      component="label"
      container
      alignItems="center"
      spacing={1}
    >
      <Grid item>Private</Grid>
      <Grid item>
        <Switch
          checked={isNhs.state}
          onChange={handleChange("state")}
          value="state"
        />
      </Grid>
      <Grid item>NHS Services</Grid>
    </Grid>
  );
}

export default NhsToggleSwitch;
