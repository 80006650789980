// components/PaymentForm.js
import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const PaymentForm = ({ data, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!elements) return;

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_frontoffice_base}/book/services/invoice?orderId=${data.id}`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <form style={{ marginTop: 30 }} onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        className="btn btn-primary"
        type="submit"
        disabled={!stripe || !elements}
        style={{ marginTop: 30 }}
      >
        Book an Appointment
      </button>
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </form>
  );
};

export default PaymentForm;
