import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiHome } from "../../assets/icons/vander";
import { withAuth0 } from "@auth0/auth0-react";
import AuthService from "../../utils/auth/auth.service";

const PatientPostAuthenticate = () => {
  // Variables
  const auth = new AuthService();
  const location = useLocation();
  const [isError, setIsError] = useState(false);

  // Use Effect
  useEffect(() => {
    if (location.hash) {
      auth
        .handleAuthentication(location.hash)
        .then((r) => {
          auth.getProfile().then((resp) => {
            window.open(`${process.env.REACT_APP_frontoffice_base}`, "_self");
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // TODO: Handle
    }
  }, []);

  return (
    <Fragment>
      <div className="back-to-home rounded d-none d-sm-block">
        <Link to="/" className="btn btn-icon btn-primary">
          <FiHome className="icons" />
        </Link>
      </div>

      <section
        className="bg-home d-flex bg-light align-items-center"
        style={{
          backgroundImage: "#202942",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="card login-page shadow mt-4 rounded border-0">
                <div
                  className="card-body text-primary"
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <div className="">
                    <Link className="logo" to="/home">
                      <span className="logo-light-mode">
                        <img
                          src={"assets/images/logo-icon.png"}
                          className="l-dark"
                          height="100"
                          alt=""
                        />
                      </span>
                    </Link>
                  </div>
                  <h4 className="text-center">Logging In</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default withAuth0(PatientPostAuthenticate);
