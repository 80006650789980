// hooks/useCustomerData.js
import { useEffect, useState } from "react";
import { getCustomer } from "../utils/usermgmt.service";

const useCustomerData = (customerId) => {
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId).then((response) => {
        if (response.data) {
          setCustomer(response.data);
        }
      });
    }
  }, [customerId]);

  return customer;
};

export default useCustomerData;
