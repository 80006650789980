import React from "react";
import "./services-search.css";

function PostalCodeInput({ postalCode, setPostalCode }) {
  return (
    <div className="col-md mt-4 mt-sm-0">
      <div className="input-group bg-white border rounded">
        <span className="input-group-text border-0">
          <i className="ri-map-pin-line text-primary h5 fw-normal mb-0"></i>
        </span>
        <input
          type="text"
          className="form-control border-0"
          placeholder="Location:"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </div>
    </div>
  );
}

export default PostalCodeInput;
