// components/InputField.js
import React from "react";

const InputField = ({
  type = "text",
  value,
  onChange,
  placeholder,
  required = false,
  validation,
  readOnly = false,
}) => (
  <div className="mb-3">
    <input
      type={type}
      className="form-control"
      required={required}
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
      readOnly={readOnly}
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    />
    {validation && !validation.valid && (
      <span className="text-danger" style={{ fontSize: "smaller" }}>
        {validation.message}
      </span>
    )}
  </div>
);

export default InputField;
