import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import { LiaTimesSolid } from "react-icons/lia";
import Header from "../../components/header/header";
import { Spinner } from "@stripe/ui-extension-sdk/ui";
import { postProductOrder } from "../../utils/backoffice.service";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { paymentIntentCallProxy } from "../../utils/stripe.service";
import { loadStripe } from "@stripe/stripe-js";
import ProductInvoice from "../../components/invoice/product-invoice";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { orderDeliveryMethods } from "../../data/data";
import {
  emailValidator,
  phoneValidator,
  postalCodeValidator,
} from "../../utils/validators";
import { getCustomer } from "../../utils/usermgmt.service";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function ShopCart({ cart, removeFromCart, clearCart }) {
  // Variables
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [loadStrip, setLoadStripe] = useState(false);
  const [stripeApiUp, setStripeApiUp] = useState(true);
  const [stripeIntentData, setStripIntentData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);
  const [data, setData] = useState(null);
  let [actionCount, setActionCount] = useState(0);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState("United Kingdom");
  const [postCode, setPostCode] = useState(null);
  const [ageConsent, setAgeConsent] = useState(false);
  const [delivery, setDelivery] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [orderCallData, setOrderCallData] = useState(0);
  const [validForm, setValidForm] = useState(false);
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: false,
    message: "",
  });
  const [emailValidation, setEmailValidation] = useState({
    valid: false,
    message: "",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] = useState({
    valid: false,
    message: "",
  });
  const [addressValidation, setAddressValidaton] = useState({
    valid: false,
    message: "",
  });
  const [cityValidation, setCityValidation] = useState({
    valid: false,
    message: "",
  });
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    valid: false,
    message: "",
  });

  let vat = {
    Standard: 0.2,
    Low: 0.05,
    Zero: 0.0,
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  // Bindings
  // Customer Id
  useEffect(() => {
    setCustomerId(sessionStorage.getItem("customerId"));
  });

  // Customer Data
  useEffect(() => {
    if (customerId) {
      getCustomer(customerId).then((response) => {
        if (response.data) {
          setCustomer(response.data);
        }
      });
    }
  }, [customerId]);

  // Pre Populate
  useEffect(() => {
    if (customer) {
      if (customer?.user?.first_nm) {
        setFirstName(customer?.user?.first_nm);
        setFirstNameValidation({ valid: true, message: "" });
      }

      if (customer?.user?.last_nm) {
        setLastName(customer?.user?.last_nm);
        setLastNameValidation({ valid: true, message: "" });
      }

      if (customer?.customer_email) {
        setEmailAddress(customer?.customer_email);
        setEmailValidation({
          valid: emailValidator(customer?.customer_email),
          message: "Please Enter Valid Email Address",
        });
      }

      if (customer?.customer_email) {
        setPhoneNumber(customer?.customer_phone);
        setPhoneNumberValidation({
          valid: phoneValidator(customer.customer_phone),
          message: "Please Enter Valid Phone Number",
        });
      }

      if (customer?.customer_addr1) {
        setAddress1(customer?.customer_addr1);
        setAddressValidaton({
          valid: true,
          message: "",
        });
      }

      if (customer?.customer_addr2) {
        setAddress2(customer?.customer_addr2);
      }

      if (customer?.customer_city) {
        setCity(customer?.customer_city);
        setCityValidation({
          valid: true,
          message: "",
        });
      }

      if (customer?.customer_zip) {
        setPostCode(customer?.customer_zip);
        setPostalCodeValidation({
          valid: postalCodeValidator(customer.customer_zip),
          message: "Please Enter Valid Postal Code",
        });
      }
    }
  }, [customer]);

  // Form Validator
  useEffect(() => {
    setValidForm(
      firstNameValidation.valid &&
        lastNameValidation.valid &&
        emailValidation.valid &&
        phoneNumberValidation.valid &&
        postalCodeValidation.valid &&
        addressValidation.valid &&
        cityValidation.valid,
    );
  }, [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    phoneNumberValidation,
    addressValidation,
    cityValidation,
    postalCodeValidation,
  ]);

  useEffect(() => {
    if (cart) {
      setData(Object.values(cart));
    }
  }, [cart, actionCount]);

  useEffect(() => {
    setDeliveryFee(getDeliveryFee(delivery));
  }, [delivery, getDeliveryFee]);

  // Functions
  function getTotals(prods) {
    let total = 0;
    // Iterate
    prods?.forEach((prod) => {
      let t = 0;
      let price = prod.price_trade ? prod.price_trade : 0;
      let quantity = prod.quantity;
      let subtotal = price * quantity;
      let vatRate = vat[prod.price_vatcode];
      t = subtotal + subtotal * vatRate;
      total += Math.round(t * 10) / 10;
    });
    return Math.round((total + deliveryFee) * 10) / 10;
  }

  // Handlers
  const firstNameChange = (e) => {
    setFirstName(e.target.value);
    if (!e.target.value) {
      setFirstNameValidation({
        valid: false,
        message: "First Name Is Required",
      });
    } else {
      setFirstNameValidation({ valid: true, message: "" });
    }
  };
  const lastNameChange = (e) => {
    setLastName(e.target.value);
    if (!e.target.value) {
      setLastNameValidation({
        valid: false,
        message: "Last Name Is Required",
      });
    } else {
      setLastNameValidation({ valid: true, message: "" });
    }
  };
  const phoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (phoneValidator(e.target.value)) {
      setPhoneNumberValidation({ valid: true, message: "" });
    } else {
      setPhoneNumberValidation({
        valid: false,
        message: "Please Enter Valid Phone Number of format 01111111111",
      });
    }
  };
  const emailAddressChange = (e) => {
    setEmailAddress(e.target.value);
    if (emailValidator(e.target.value)) {
      setEmailValidation({ valid: true, message: "" });
    } else {
      setEmailValidation({
        valid: false,
        message: "Please Enter Valid Email Address",
      });
    }
  };
  const address1Change = (e) => {
    setAddress1(e.target.value);
    if (!e.target.value) {
      setAddressValidaton({
        valid: false,
        message: "Address Line 1 Is Required",
      });
    } else {
      setAddressValidaton({ valid: true, message: "" });
    }
  };
  const address2Change = (e) => {
    setAddress2(e.target.value);
  };
  const cityChange = (e) => {
    setCity(e.target.value);
    if (!e.target.value) {
      setCityValidation({
        valid: false,
        message: "City Is Required",
      });
    } else {
      setCityValidation({ valid: true, message: "" });
    }
  };
  const countryChange = (e) => {
    setCountry(e.target.value);
  };
  const postCodeChange = (e) => {
    setPostCode(e.target.value);
    if (postalCodeValidator(e.target.value)) {
      setPostalCodeValidation({ valid: true, message: "" });
    } else {
      setPostalCodeValidation({
        valid: false,
        message: "Please Enter Valid Postal Code",
      });
    }
  };

  const selectCountry = (e) => {
    // Set Value
    setCountry(e.target.value);
  };

  function getItemTotal(prod) {
    let t = 0;
    let price = prod.price_trade ? prod.price_trade : 0;
    let quantity = prod.quantity;
    let subtotal = price * quantity;
    let vatRate = vat[prod.price_vatcode];
    t += subtotal + subtotal * vatRate;
    return Math.round(t * 10) / 10;
  }

  function callStripIntent(data) {
    let payload = {
      amount: data.amount * 100,
      currency: "gbp",
      "automatic_payment_methods[enabled]": true,
      orderId: data.orderId,
      receipt_email: data.emailAddress,
      type: "product",
    };
    paymentIntentCallProxy(payload)
      .then((r) => {
        setStripIntentData(r.data);
        setClientSecret(r.data.client_secret);
        setLoadStripe(true);
        setStripeApiUp(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 503) {
          setStripeApiUp(false);
        }
      });
  }

  function getCartProductTaxAmount(prod) {
    let tax = 0;
    let rate = vat[prod.price_vatcode];
    let subtotal = prod.price_trade * prod.quantity;
    tax = Math.round(subtotal * rate * 10) / 10;
    return tax;
  }

  function getCartProductSubtotal(prod) {
    let tax = 0;
    let rate = vat[prod.price_vatcode];
    let subtotal = prod.price_trade * prod.quantity;
    tax = Math.round(subtotal * rate * 10) / 10;
    subtotal = Math.round((subtotal + tax) * 10) / 10;
    return subtotal;
  }

  function getProducts(prods) {
    prods = prods.filter((e) => (e.price_trade > 0) & (e.quantity > 0));
    return prods.map((cartObj) => {
      return {
        productId: cartObj.productId,
        productName: cartObj.labels_fulldescription,
        productCost: cartObj.price_trade,
        productCount: cartObj.quantity,
        productVat: cartObj.price_vatcode,
        productTaxAmount: getCartProductTaxAmount(cartObj),
        productSubTotalAmount: getCartProductSubtotal(cartObj),
      };
    });
  }

  function getDeliveryFee(del) {
    let d = orderDeliveryMethods.filter((i) => i.value === del);
    console.log(d);
    return d[0]?.amount || 0;
  }

  let proceedToCheckOut = (prods) => {
    // PayLoad
    let payload = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
      addressLine1: address1,
      addressLine2: address2,
      cityName: city,
      postalCode: postCode,
      countryName: country,
      products: getProducts(prods),
      orderTotal: getTotals(prods),
      orderStatus: "Placed",
      deliveryType: delivery,
      deliveryFee: deliveryFee,
    };

    // Make Order Call
    postProductOrder(payload).then((results) => {
      if (results.data) {
        setOrderCallData(results.data);
      }
      // Post Order Data
      callStripIntent({
        amount: Math.round(getTotals(prods)),
        orderId: results.data.id,
        emailAddress: results.data.emailId,
      });
    });
  };

  let removeProductFromCart = (productId) => {
    removeFromCart(productId, cart);
    setActionCount((actionCount += 1));
  };

  return (
    <Fragment>
      <Header />
      <section className="bg-half-170 d-table w-100 bg-dark">
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-4 text-light">
                  Your Shopping Cart
                </h3>

                <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                  <ul className="breadcrumb bg-light rounded mb-0 bg-transparent">
                    <li className="breadcrumb-item">
                      <Link to="/">ZipLaneRx</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/pharmacy">Pharmacy</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Shop Cart
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        {showInvoice ? (
          <div className="pt-5" id="invoice">
            <ProductInvoice data={orderCallData} />
          </div>
        ) : (
          <div className="container">
            <div className="row">
              {!data || data.length === 0 ? (
                <div
                  className="col-12"
                  style={{ marginTop: 5, marginBottom: 10 }}
                >
                  <h5>You Cart is Empty!</h5>
                </div>
              ) : null}

              <div className="col-12">
                <div className="table-responsive bg-white shadow rounded">
                  <table className="table table-center table-padding mb-0">
                    <thead>
                      <tr>
                        <th
                          className="border-bottom p-3"
                          style={{ minWidth: "20px" }}
                        ></th>
                        <th
                          className="border-bottom p-3"
                          style={{ minWidth: "300px" }}
                        >
                          Product
                        </th>
                        <th
                          className="border-bottom text-center p-3"
                          style={{ minWidth: "160px" }}
                        >
                          Price
                        </th>
                        <th
                          className="border-bottom text-center p-3"
                          style={{ minWidth: "160px" }}
                        >
                          Qty
                        </th>
                        <th
                          className="border-bottom text-center p-3"
                          style={{ minWidth: "160px" }}
                        >
                          VAT
                        </th>
                        <th
                          className="border-bottom text-end p-3"
                          style={{ minWidth: "150px" }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.map((item, index) => {
                        if (cart[item.productId].quantity > 0) {
                          return (
                            <tr key={index}>
                              <td className="h5 p-3 text-center">
                                <Link
                                  to=""
                                  className="text-danger"
                                  onClick={() => {
                                    removeProductFromCart(item.productId);
                                  }}
                                >
                                  <LiaTimesSolid />
                                </Link>
                              </td>
                              <td className="p-3">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={`data:image/gif;base64,${item.image}`}
                                    className="img-fluid avatar avatar-small rounded shadow"
                                    style={{ width: 50, height: 50 }}
                                    alt=""
                                  />
                                  <h6 className="mb-0 ms-3">
                                    <Link
                                      to={`/shop/products/${item.productId}`}
                                    >
                                      {item.labels_fulldescription}
                                    </Link>
                                  </h6>
                                </div>
                              </td>
                              <td className="text-center p-3">
                                {item.price_trade ? `£ ${item.price_trade}` : 0}
                              </td>
                              <td className="text-center shop-list p-3">
                                {cart[item.productId].quantity}
                              </td>
                              <td className="text-center shop-list p-3">
                                {item.price_vatcode}
                              </td>
                              <td className="text-end font-weight-bold p-3">
                                £ {getItemTotal(item)}
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              {/*TODO: change to all*/}
              {/*<div className="col-lg-8 col-md-6 mt-4 pt-2">*/}
              {/*  <Link to="/shop/appliances" className="btn btn-primary">*/}
              {/*    Shop More*/}
              {/*  </Link>*/}
              {/*  /!*<Link to="#" className="btn btn-soft-primary ms-2">*!/*/}
              {/*  /!*  Update Cart*!/*/}
              {/*  /!*</Link>*!/*/}
              {/*</div>*/}
              <div className="col-lg-4 col-md-6 ms-auto mt-4 pt-2">
                <div className="table-responsive bg-white rounded shadow">
                  <table className="table table-center table-padding mb-0">
                    <tbody>
                      <tr>
                        <td className="h6 p-3">Delivery Fee</td>
                        <td className="text-end font-weight-bold p-3">
                          £ {deliveryFee}
                        </td>
                      </tr>
                      <tr>
                        <td className="h6 p-3">Total</td>
                        <td className="text-end font-weight-bold p-3">
                          £ {getTotals(data)}
                        </td>
                      </tr>
                      {/*<tr>*/}
                      {/*  <td className="h6 p-3">VAT</td>*/}
                      {/*  <td className="text-end font-weight-bold p-3">$ 219</td>*/}
                      {/*</tr>*/}
                      {/*<tr className="bg-light">*/}
                      {/*  <td className="h6 p-3">Total</td>*/}
                      {/*  <td className="text-end font-weight-bold p-3">$ 2409</td>*/}
                      {/*</tr>*/}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4 mt-sm-0 pt-2 pt-md-4">
              <div
                className="card border-0 rounded shadow p-4 ms-xl-3"
                style={{ backgroundColor: "#ffffff" }}
              >
                <div
                  className="custom-form"
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Patient First Name <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="firstName"
                            id="firstName"
                            type="text"
                            className="form-control"
                            required={true}
                            placeholder="Patient First Name :*"
                            value={firstName}
                            onChange={firstNameChange}
                          />
                          {!firstNameValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {firstNameValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Patient Last Name <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="lastName"
                            id="lastName"
                            type="text"
                            className="form-control"
                            required={true}
                            placeholder="Patient Last Name :*"
                            value={lastName}
                            onChange={lastNameChange}
                          />
                          {!lastNameValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {lastNameValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Your Email <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            required={true}
                            placeholder="Your email :*"
                            value={emailAddress}
                            onChange={emailAddressChange}
                          />
                          {!emailValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {emailValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Your Phone <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="phone"
                            id="phone"
                            type="tel"
                            className="form-control"
                            required={true}
                            placeholder="Your Phone :*"
                            value={phoneNumber}
                            onChange={phoneNumberChange}
                          />
                          {!phoneNumberValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {phoneNumberValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Your Phone <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="address1"
                            id="address1"
                            type="tel"
                            className="form-control"
                            required={true}
                            placeholder="Address Line 1: *"
                            value={address1}
                            onChange={address1Change}
                          />
                          {!addressValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {addressValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Your Phone <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="address2"
                            id="address2"
                            type="tel"
                            className="form-control"
                            // required={true}
                            placeholder="Address Line 2: "
                            value={address2}
                            onChange={address2Change}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Your Phone <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="city"
                            id="city"
                            type="tel"
                            className="form-control"
                            required={true}
                            placeholder="City: *"
                            value={city}
                            onChange={cityChange}
                          />
                          {!cityValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {cityValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          {/*<label className="form-label">*/}
                          {/*  Your Phone <span className="text-danger">*</span>*/}
                          {/*</label>*/}
                          <input
                            name="zip"
                            id="zip"
                            type="zip"
                            className="form-control"
                            required={true}
                            placeholder="Postal Code: *"
                            value={postCode}
                            onChange={postCodeChange}
                          />
                          {!postalCodeValidation.valid ? (
                            <span
                              className="text-danger"
                              style={{ fontSize: "smaller" }}
                            >
                              {" "}
                              {postalCodeValidation.message}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Country</label>
                          <input
                            name="country"
                            id="country"
                            type="tel"
                            className="form-control"
                            required={true}
                            value={country}
                            disabled={true}
                            // placeholder="Postal Code: *"
                            // onChange={postCodeChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">Delivery Method</label>
                          <select
                            className="form-control"
                            value={delivery}
                            onChange={(e) => {
                              setDelivery(e.target.value);
                              setDeliveryFee(getDeliveryFee(e.target.value));
                              console.log(e.target.value);
                            }}
                          >
                            <option defaultValue="default">
                              Select Delivery Method
                            </option>
                            {orderDeliveryMethods
                              ? orderDeliveryMethods.map((met) => {
                                  return (
                                    <option key={met.value} value={met.value}>
                                      {`${met.name}: ${met.desc}`}
                                    </option>
                                  );
                                })
                              : []}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="d-grid">
                          <div className="form-check">
                            <FormGroup>
                              <FormControlLabel
                                required
                                control={<Switch />}
                                checked={ageConsent}
                                onChange={() =>
                                  setAgeConsent((prevState) => !prevState)
                                }
                                label="I am 18 and above, booking my own appointment, and accept ZipLaneRx’s Terms."
                                style={{
                                  fontSize: "xx-small",
                                  textAlign: "start",
                                }}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12"></div>
            </div>
            <div className="mt-4 pt-2 text-end">
              <button
                className="btn btn-primary"
                onClick={() => {
                  proceedToCheckOut(data);
                }}
                disabled={getTotals(data) <= 0 || !ageConsent || !validForm}
              >
                Proceed to checkout
              </button>
            </div>
            {data && loadStrip ? (
              <div className="pt-0">
                <Elements stripe={stripePromise} options={options}>
                  <PaymentForm
                    orderData={orderCallData}
                    clientSecret={clientSecret}
                  />
                </Elements>
              </div>
            ) : data && !stripeApiUp ? (
              <div className="d-grid">
                <p>
                  {" "}
                  <span className="fw-bolder text-danger">
                    Stripe API is down{" "}
                  </span>
                  <span className="text-primary">{firstName}!</span>
                </p>
                <p>
                  <span className="text-danger">Please try again later!</span>
                </p>
              </div>
            ) : null}
          </div>
        )}
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}

const PaymentForm = ({ orderData, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  // Payment Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_frontoffice_base}/shop/products/invoice?orderId=${orderData.id}`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      // Make Payment Call
    }
  };

  return (
    <form style={{ marginTop: 30 }} onSubmit={handleSubmit}>
      <Spinner />
      <PaymentElement />
      <button
        className="btn btn-primary"
        type="submit"
        disabled={!stripe || !elements}
        style={{ marginTop: 30 }}
      >
        Place Order
      </button>
    </form>
  );
};
