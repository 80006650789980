import axios from "axios";
import moment from "moment";

const baseUrl = process.env.REACT_APP_sales_base;

export async function searchCustomerAppointments(criteria) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/query/`,
    criteria,
    config,
  );
  return result.data.sort(
    (a, b) =>
      moment(a.appointment_booked_dttm) - moment(b.appointment_booked_dttm),
  );
}

export async function searchCustomerOrders(criteria) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/orders/query/`,
    criteria,
    config,
  );
  return result.data;
}

export async function getOrderBooking(bookind_id) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.get(
    `${baseUrl}/api/orders/booking/${bookind_id}`,
    config,
  );
  return result.data;
}

export async function getOverBookedSlots(payload) {
  // Headers
  const config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("_accessToken")}`,
    },
  };

  let result = await axios.post(
    `${baseUrl}/api/appointments/get-over-booked-slots`,
    payload,
    // { p_nbr: 56, date: "2024-09-25" },
    config,
  );
  return result.data;
}
