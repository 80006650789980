import axios from "axios";

const baseUrl = process.env.REACT_APP_practitioner_base;

export async function getPractitionerByOds(organizationId, odsCode) {
  return axios.get(`${baseUrl}/api/practitioners/${organizationId}/${odsCode}`);
}

export async function getPractitionerById(p_nbr) {
  return axios.get(`${baseUrl}/api/practitioners/${p_nbr}`);
}

export async function getPractitionerService(p_nbr, serviceId) {
  return axios.get(`${baseUrl}/api/services/srvc/${p_nbr}/${serviceId}`);
}

export async function searchServicesAndPharmacies(data) {
  return axios.post(`${baseUrl}/api/services/active/search`, data);
}

export async function getPractitionerStripeAccount(p_nbr) {
  return axios.get(`${baseUrl}/api/bank/practitioner/${p_nbr}`);
}

export async function searchPharmacies(data) {
  return axios.post(`${baseUrl}/api/practitioners/query`, data);
}

export async function getActiveServices(p_nbr) {
  return axios.get(`${baseUrl}/api/services/active/${p_nbr}`);
}

export async function getBlockedSlots(payload) {
  return axios.post(`${baseUrl}/api/calendar-slots/blocked/`, payload);
}
