import React, { useEffect, useState } from "react";
import "./services-search.css";
import { Link } from "react-router-dom";

function ServiceExplore({
  services,
  selectService,
  selectedServiceObject,
  resetSelection,
}) {
  const [servicesActiveIndex, setServicesActiveIndex] = useState(1);
  const [nhsServices, setNhsServices] = useState([]);
  const [privateServices, setPrivateServices] = useState([]);
  const [privateServicesGrouped, setPrivateServicesGrouped] = useState([]);
  const [privateServicesCategory, setPrivateServicesCategory] = useState(null);

  useEffect(() => {
    setNhsServices(services.filter((service) => service.isNhs));
    setPrivateServices(services.filter((service) => !service.isNhs));
  }, [services]);

  useEffect(() => {
    let grouped = privateServices.reduce((acc, current) => {
      const key = current.srvc_type;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(current);
      return acc;
    }, {});
    setPrivateServicesGrouped(grouped);
  }, [privateServices]);

  return (
    <div className="col-12 mt-0">
      <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-0 shadow overflow-hidden bg-light mt-3 mb-0">
        {nhsServices?.length > 0 && (
          <li className="nav-item">
            <Link
              className={`${
                servicesActiveIndex === 1 ? "active" : ""
              } nav-link rounded-0 fw-bolder`}
              style={{ fontSize: "medium" }}
              to={null}
              onClick={() => {
                resetSelection();
                setServicesActiveIndex(1);
              }}
            >
              NHS Funded
            </Link>
          </li>
        )}

        {privateServices?.length > 0 && (
          <li className="nav-item">
            <Link
              className={`${
                servicesActiveIndex === 0 ? "active" : ""
              } nav-link rounded-0 fw-bolder`}
              style={{ fontSize: "medium" }}
              to={null}
              onClick={() => {
                resetSelection();
                setServicesActiveIndex(0);
              }}
            >
              Private
            </Link>
          </li>
        )}
      </ul>
      <div
        className="col-12"
        style={{
          height: "fit-content",
          maxHeight: "250pt",
          boxSizing: "border-box",
          margin: "0",
          overflowY: "auto",
          contentVisibility: "auto",
          overflowMargin: "content-box",
        }}
      >
        <div className="tab-pane fade show active shadow rounded-0 py-0 px-0">
          <div className="table-responsive bg-white shadow rounded-0 mb-0">
            {servicesActiveIndex === 1 ? (
              <table
                className="table mb-0 table-center table-wrap"
                style={{ fontSize: "medium" }}
              >
                {/*<thead className="text-start">*/}
                {/*  <tr>*/}
                {/*    <th className="border-bottom p-3" scope="col">*/}
                {/*      Service*/}
                {/*    </th>*/}
                {/*    {servicesActiveIndex === 0 ? (*/}
                {/*      <th className="border-bottom p-3" scope="col">*/}
                {/*        Cost*/}
                {/*      </th>*/}
                {/*    ) : null}*/}
                {/*  </tr>*/}
                {/*</thead>*/}
                <tbody className="text-start">
                  {nhsServices.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`p-3 ${
                          selectedServiceObject === item ? "table-active" : ""
                        }`}
                        onClick={() => {
                          selectService(item);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="p-3">
                          {item.serviceName.toUpperCase()}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
            {servicesActiveIndex === 0 ? (
              <div className="accordion">
                {Object.keys(privateServicesGrouped).map((item, index) => {
                  return (
                    <div
                      className="accordion-item border rounded mt-2 mx-2"
                      key={index}
                    >
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={`${
                            privateServicesCategory === item ? "" : "collapsed"
                          } accordion-button border-0 bg-light px-2`}
                          type="button"
                          onClick={() => {
                            privateServicesCategory !== item
                              ? setPrivateServicesCategory(item)
                              : setPrivateServicesCategory(null);
                          }}
                        >
                          {item !== "null" ? item : "Other"}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className={`${
                          privateServicesCategory === item ? "show" : ""
                        } accordion-collapse border-0 collapse`}
                      >
                        <div className="accordion-body text-muted">
                          <table
                            className="table mb-0 table-center table-wrap"
                            style={{ fontSize: "medium" }}
                          >
                            <thead className="text-start">
                              <tr>
                                <th className="border-bottom p-3" scope="col">
                                  Service
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-start">
                              {privateServicesGrouped[item].map((i, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={`p-3 ${
                                      selectedServiceObject === i
                                        ? "table-active"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      selectService(i);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td className="p-3">
                                      {i.serviceName.toUpperCase()}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceExplore;
