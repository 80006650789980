import axios from "axios";

const baseUrl = process.env.REACT_APP_STRIPE_BASE_URL;
const stripe_secret = process.env.REACT_APP_STRIPE_CLIENT_SECRET;
const proxyBase = process.env.REACT_APP_proxy_base;

export async function paymentIntentCall(data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${stripe_secret}`,
  };
  return axios.post(`${baseUrl}/payment_intents`, data, { headers: headers });
}

export async function paymentIntentCallProxy(data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // Authorization: `Bearer ${stripe_secret}`,
  };
  return axios.post(`${proxyBase}/api/stripe/payment_intents`, data, {
    headers: headers,
  });
}

export async function createPractitionerInvoice(data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // Authorization: `Bearer ${stripe_secret}`,
  };
  return axios.post(
    `${proxyBase}/api/stripe/create_practitioner_invoice`,
    data,
    {
      headers: headers,
    },
  );
}

export async function createAccount(data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // Authorization: `Bearer ${stripe_secret}`,
  };
  return axios.post(`${proxyBase}/api/stripe/account`, data, {
    headers: headers,
  });
}

export async function updateAccount(data, accountID) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // Authorization: `Bearer ${stripe_secret}`,
  };
  return axios.post(`${proxyBase}/api/stripe/account/${accountID}`, data, {
    headers: headers,
  });
}

export async function getAccountSession(data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // Authorization: `Bearer ${stripe_secret}`,
  };
  return axios.post(`${proxyBase}/api/stripe/account-session`, data, {
    headers: headers,
  });
}

export async function getCheckoutSession(data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // Authorization: `Bearer ${stripe_secret}`,
  };
  return axios.post(
    `${proxyBase}/api/stripe/subscription-checkout-session`,
    data,
    {
      headers: headers,
    },
  );
}
